import React from 'react'
import { useNavigate } from "react-router-dom";
import "./CustomError.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { get_platform_bg_color } from '../../script/util';


const CustomeError = ({errMessage}) => {
    const navigate = useNavigate();

    return (
      <div className="error-container">
        <div className="error-box" style={{backgroundColor: get_platform_bg_color()}}>
        <div className='error-gif'>
            <img src={"https://assets.graphia.in/site_media/react/error.webp"} alt="error" />
          </div>
          <h2 className="error-title">Oops! Something went wrong.</h2>
          <p className="error-message">{errMessage}.</p>
          <button
            onClick={() => navigate("/dashboard")}
            className="error-button"
          >
            <FontAwesomeIcon icon={faArrowLeft} size='lg' style={{color: "#000000",}} />
            <span>Go to Dashboard</span>
          </button>
        </div>
      </div>
    );
}

export default CustomeError;