export function get_global_can_add_to (data) {
    if (data?.photo_data) {
        const result = data.photo_data.reduce((acc, item) => acc || item.can_add_to, false);
        return result;
    }
    return false;
}

export function get_global_is_album_required (data) {
    if (data?.photo_data) {
        const result = data.photo_data.reduce((acc, item) => acc || item.is_album_required, false);
        return result;
    }
    return false;
}

export function get_global_can_make_private (data) {
    if(data?.photo_data){
        const result = data.photo_data.reduce((acc, item) => acc || item.can_make_private, false);
        return result;
    }
    return false;
}

export function get_global_is_downloadable (data) {
    if(data?.photo_data){
        const result = data.photo_data.reduce((acc, item) => acc || item.is_downloadable, false);
        return result;
    }
    return false;
}

export function get_can_add_to (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.can_add_to;
            }
        }
    }
}

export function get_is_album_required (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.is_album_required;
            }
        }
    }
}

export function get_can_make_private (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.can_make_private;
            }
        }
    }
}

export function get_is_downloadable (photoId, data) {
    if(data?.photo_data){
        for (const item of data?.photo_data) {
            if (item.data.some(photo => photo.photo_id === photoId)) {
                return item.is_downloadable;
            }
        }
    }
}

export function get_can_delete (photoId, data) {
    if(data?.photo_data) {
        const photo = data?.photo_data
            .flatMap(e => e.data) 
            .find(ele => ele.photo_id === photoId);
        if(photo){
            return photo.can_delete;
        }
    }
}

export function get_selected_workId (data, photoId) {
    const res = Object.keys(data).find((key) => 
        data[key].some(obj => obj.photo_id === photoId)
    );
    return Number(res);
}

export function get_user_liked_photos (data, photoId) {
    if (!data) return false;

    for (const key of Object.keys(data)) {
        for (const e of Object.keys(data[key])) {
            if (e == photoId) {
                return data[key][e].user_liked;
            }
        }
    }

    return false;
}

export function getApiMethod (selectionType, tab) {
    const albumSelection = 'albumselection'
    const deleteUploads = 'delete_guest_gallery'
    const likePhotos = 'like_photos'
    const viewPhotos = 'view_photos'
    return (tab === albumSelection && selectionType === deleteUploads) || 
           selectionType === likePhotos || 
           selectionType === viewPhotos 
           ? "PUT" 
           : "POST";
};

export function sort_data_by_is_downloadable (data, collection, collectionFormattedData) {
    const sortedData = data ? [...data].sort((a, b) => {
        const a_downloadable = collection?.photo_data.find((obj)=>obj.work_id==get_selected_workId(collectionFormattedData, a?.photo_id))?.is_downloadable
        const b_downloadable = collection?.photo_data.find((obj)=>obj.work_id==get_selected_workId(collectionFormattedData, b?.photo_id))?.is_downloadable
        if (a_downloadable !== b_downloadable) {
            return b_downloadable - a_downloadable;
        }
        return b.epoch_time - a.epoch_time;
    }) : [];
    return sortedData;
}

export function get_liked_data_is_downloadable (collection, collectionFormattedData, photo_obj) {
    return collection?.photo_data.find((obj)=>obj.work_id==get_selected_workId(collectionFormattedData, photo_obj?.photo_id)).is_downloadable
}

export function getCurrentPage (CONST, tab) {
    const guestUpload = 'guestupload'
    const albumSelection = 'albumselection'
    const userFolders = 'userfolders'
    let currentPage;
    switch (tab) {
        case guestUpload:
            currentPage = CONST.GUEST_UPLOADS;
            break;
        case albumSelection:
            currentPage = CONST.ALBUM_SELECTION;
            break;
        case userFolders:
            currentPage = CONST.USER_FOLDERS;
            break;
        default:
            currentPage = CONST.WORK_DETAILS;
    }
    return currentPage;
}