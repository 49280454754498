import jsPDF from 'jspdf';
import eg_logo from '../pages/EGQuotation/Quotation_images/eg_logo.png';
import wg_logo from '../pages/WGQuotation/Quotation_images/wg_logo.png'
import badge_backdrop from '../pages/EGQuotation/Quotation_images/badge-backdrop.png';
import WMG_badge from '../pages/EGQuotation/Quotation_images/WMG-badge.png';
import WS_badge from '../pages/EGQuotation/Quotation_images/WS-badge.png';
import WSP from '../pages/EGQuotation/Quotation_images/badge-runnerup(2024).png';
import whyEG from '../pages/EGQuotation/Quotation_images/whyEventgraphia.jpg';
import whyWG from '../pages/WGQuotation/Quotation_images/whyWG.jpg';
import delDiv from '../pages/EGQuotation/Quotation_images/del-div.jpg';
import delDivWG from '../pages/WGQuotation/Quotation_images/del-div-wg.jpg';
import prePost from '../pages/EGQuotation/Quotation_images/pre_post.jpg';
import prePostWG from '../pages/WGQuotation/Quotation_images/pre-post-wg.jpg';
import Akshay from '../pages/WGQuotation/Quotation_images/Akshay.JPG';
import artecallya from '../fonts/artecallya/Artecallya-Script.ttf'
import montserratMedium from '../fonts/montserrat/Montserrat-Medium.ttf'
import montserrat from '../fonts/montserrat/Montserrat-Regular.ttf'
import { Platform, termsConditions } from "./util"

class PDFGenerator {
    constructor(data, isWedding) {
        this.data = data;
        this.pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'in',
            format: [15, 7.5],
            compress: true,
        });
        this.bgColor = Platform.isEG ? [233, 196, 187] : [212, 191, 172];
        this.textColor = Platform.isEG ? [159, 120, 102] : [84, 77, 68];
        this.company = Platform.isEG ? 'EventGraphia' : 'WedGraphia';
        this.isWedding = isWedding;
    }

    loadImage(src) {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
        });
    }

    getImageDimensions(src) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                });
            };
            img.src = src;
        });
    }

    async coverPage() {
        const { pdf } = this;
    
        pdf.setFillColor(...this.bgColor);
        pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
    
        const logoSrc = Platform.isEG ? eg_logo : wg_logo;
        
        const img = await this.loadImage(logoSrc);
        const imageDimensions = await this.getImageDimensions(logoSrc);
    
        const originalWidth = imageDimensions.width;
        const originalHeight = imageDimensions.height;
    
        const aspectRatio = originalWidth / originalHeight;
    
        const maxWidth = Platform.isEG ? pdf.internal.pageSize.width * 0.25 : pdf.internal.pageSize.width * 0.5;
        const maxHeight = Platform.isEG ? pdf.internal.pageSize.height * 0.25 : pdf.internal.pageSize.height * 0.5;
    
        let imgWidth, imgHeight;
    
        if (maxWidth / aspectRatio <= maxHeight) {
            imgWidth = maxWidth;
            imgHeight = maxWidth / aspectRatio;
        } else {
            imgHeight = maxHeight;
            imgWidth = maxHeight * aspectRatio;
        }
    
        const imgX = (pdf.internal.pageSize.width - imgWidth) / 2;
        const imgY = (pdf.internal.pageSize.height - imgHeight) / 2-1;
    
        const spaceBetween = 0.80;
    
        pdf.addImage(img, 'JPEG', imgX, imgY, imgWidth, imgHeight);
    
        const text = 'Making Memories';
        pdf.setTextColor(...this.textColor);
        pdf.setFontSize(60);
        pdf.addFont(artecallya, 'artecallya', 'normal');
        pdf.setFont('artecallya');
        
        const textWidth = pdf.getTextWidth(text);
        const textX = (pdf.internal.pageSize.width - textWidth) / 2;
        const textY = imgY + imgHeight + spaceBetween;
    
        if (Platform.isEG) {
            pdf.text(text, textX, textY);
        }
    }

    async awardPage() {
        const { pdf } = this;
        pdf.addPage();

        const imgWidth2 = 4;
        const imgHeight2 = 4;
        const imgY2 = (pdf.internal.pageSize.height - imgHeight2) / 2;

        const bgImg = await this.loadImage(badge_backdrop);
        const sideImg1 = await this.loadImage(WMG_badge);
        const sideImg2 = await this.loadImage(WSP);
        const sideImg3 = await this.loadImage(WS_badge);

        pdf.addImage(bgImg, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);

        const dividerX = pdf.internal.pageSize.width / 3;
        const dividerY = imgY2;
        const dividerHeight = imgHeight2;

        pdf.setDrawColor(189, 200, 222);
        pdf.setLineWidth(0.07);
        pdf.line(dividerX, dividerY, dividerX, dividerY + dividerHeight);
        pdf.line(dividerX*2, dividerY, dividerX*2, dividerY + dividerHeight);

        const imgX1 = 0.5;
        const imgX2 = dividerX + 0.5;
        const imgX3 = 2*dividerX + 0.5;

        pdf.addImage(sideImg2, 'JPEG', imgX1, imgY2, imgWidth2, imgHeight2);
        pdf.addImage(sideImg1, 'JPEG', imgX2, imgY2, imgWidth2, imgHeight2);
        pdf.addImage(sideImg3, 'JPEG', imgX3, imgY2, imgWidth2, imgHeight2);
    }

    async nonWeddingWhy() {
        const { pdf } = this;

        pdf.addPage();

        const bgImg = await this.loadImage(Akshay);
        pdf.addImage(bgImg, 'PNG', 0, 0, pdf.internal.pageSize.width/3, pdf.internal.pageSize.height);

        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.setFontSize(42);
        pdf.setTextColor(...this.textColor);
        const title =  Platform.isEG ? 'WHY EVENTGRAPHIA?' : 'WHY WEDGRAPHIA?';
        pdf.text(title, pdf.internal.pageSize.width/3 + 0.5, 1.2);

        pdf.setFontSize(20);
        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.setTextColor(...this.textColor);

        const description = [
            `${this.company} is a Premium Event Photography Service space.`,
            "We provide seamless end-to-end solutions right from booking",
            "inquiry to the delivery of photos, videos and albums, all digitally",
            "using our web app."
        ];
        
        let currentY = 2;
        description.forEach(line => {
            pdf.text(line, pdf.internal.pageSize.width/3 + 0.5, currentY);
            currentY += 0.4;
        });

        const bulletPoints = [
            "• India's Largest Event Photography Company",
            "• Serving 175+ Cities",
            "• 210+ Professionals",
            "• Covered 500+ Weddings",
            "• State of the art Equipments",
            "• Customers - BMW, TAJ, Uber, Dominos, Harley Davidson,",
            "  Hello English, Oyo Rooms"
        ];
        
        currentY += 0.3;
        bulletPoints.forEach(point => {
            pdf.text(point, pdf.internal.pageSize.width/3 + 0.5, currentY);
            currentY += 0.5;
        });

    }

    async schedulePage(value) {
        const { pdf } = this;
    
        const addNewPage = () => {
            pdf.addPage();
            pdf.setFillColor(251, 247, 244);
            pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
        };
    
        const title = 'SCHEDULE';
    
        const renderTitle = (y) => {
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.setFontSize(42);
            pdf.setTextColor(...this.textColor);
            const pageWidth = pdf.internal.pageSize.width;
            const titleWidth = pdf.getTextWidth(title);
            pdf.text(title, (pageWidth - titleWidth) / 2, y);
        };
    
        const renderDateSection = (date, events, x, y) => {
            pdf.setFontSize(24);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.text(date, x, y);
    
            pdf.setFontSize(20);
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
    
            let currentY = y + 0.8;
            events.forEach(event => {
                pdf.text(`• ${event}`, x, currentY);
                currentY += 0.6;
            });
        };
    
        const maxDatesPerPage = 4;
        const boxWidth = 2.5;
        const titleY = 1.5;
        const rowHeight = 3;
    
        let currentY = titleY + 1.5;
        let pageIndex = 0;
    
        const calculatePositions = (numDates) => {
            const pageWidth = pdf.internal.pageSize.width;
            const totalRowWidth = numDates * boxWidth;
            const spacing = (pageWidth - totalRowWidth) / (numDates + 1);
            const positions = [];
            for (let i = 0; i < numDates; i++) {
                positions.push(spacing * (i + 1) + i * boxWidth);
            }
            return positions;
        };
    
        for (let i = 0; i < value.length; i += maxDatesPerPage) {
            const datesOnPage = value.slice(i, i + maxDatesPerPage);
            const positions = calculatePositions(datesOnPage.length);
    
            addNewPage();
            renderTitle(titleY);
            currentY = titleY + 1;
    
            datesOnPage.forEach((dateItem, index) => {
                renderDateSection(dateItem.date_data.date_str, dateItem.events_name, positions[index], currentY);
            });
    
            currentY += rowHeight;
        }
    }
    
    async eventsPage(values) {
        const { pdf } = this;
    
        const addNewPage = () => {
            pdf.addPage();
            pdf.setFillColor(...this.bgColor);
            pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
        };
    
        const renderTitle = (date, y) => {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const padding = 1;
            const availableWidth = pageWidth - padding * 2;
            const lineHeightTitle = pdf.getLineHeight() / pdf.internal.scaleFactor;
    
            pdf.setFontSize(36);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
    
            const wrappedTextTitle = pdf.splitTextToSize(date, availableWidth);
    
            pdf.text(wrappedTextTitle, padding, y);
    
            const titleHeight = wrappedTextTitle.length * lineHeightTitle;
            const extraSpace = 1;
            let currentY = y + titleHeight + extraSpace;
    
            return currentY;
        };
    
        const renderEvent = (event, name, x, y) => {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const secondColumnX = pageWidth / 2 + 0.5;
    
            pdf.setFontSize(24);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.text(name, x, y);
    
            pdf.setFontSize(18);
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            let currentY = y + 0.3;
            pdf.setFontSize(16);
            pdf.text(`${event.venue_str}`, x, currentY);
            pdf.setFontSize(20);
    
            currentY += 0.5;
    
            const totalItems = event.event_products_cnt.length + (event.notes ? event.notes.length : 0);
            const maxItemsPerColumn = 7;
    
            let itemIndex = 0;
    
            for (; itemIndex < event.event_products_cnt.length && itemIndex < maxItemsPerColumn; itemIndex++) {
                pdf.text(`• ${event.event_products_cnt[itemIndex]}`, x, currentY);
                currentY += 0.46;
            }
    
            let secondColumnY = y + 0.3;
            if (totalItems > maxItemsPerColumn) {
                for (; itemIndex < event.event_products_cnt.length; itemIndex++) {
                    pdf.text(`• ${event.event_products_cnt[itemIndex]}`, secondColumnX, secondColumnY);
                    secondColumnY += 0.5;
                }
    
                if (event.notes) {
                    pdf.setFontSize(15);
                    secondColumnY += 0.1;
                    event.notes.forEach(note => {
                        const splitText = pdf.splitTextToSize(`*${note}`, 4.5);
                        splitText.forEach(line => {
                            pdf.text(line, secondColumnX, secondColumnY);
                            secondColumnY += 0.35;
                        });
                    });
                }
    
                if (event?.coverage_until) {
                    pdf.setFontSize(15);
                    pdf.text(`*${event?.display_coverage}`, secondColumnX, secondColumnY);
                }
            } else {
                if (event.notes) {
                    pdf.setFontSize(15);
                    currentY += 0.1;
                    event.notes.forEach(note => {
                        const splitText = pdf.splitTextToSize(`*${note}`, 4.5);
                        splitText.forEach(line => {
                            pdf.text(line, x, currentY);
                            currentY += 0.35;
                        });
                    });
                }
    
                if (event?.coverage_until) {
                    pdf.setFontSize(15);
                    pdf.text(`*${event?.display_coverage}`, x, currentY);
                }
            }
    
            return Math.max(currentY, secondColumnY);
        };
    
        const titleY = 1.2;
        values.forEach(eventItem => {
            addNewPage();
    
            let currentY = titleY;
    
            currentY = renderTitle(eventItem.day_info, currentY);
            const secondEventY = currentY
            let eventIndex = 0;
    
            while (eventIndex < eventItem.event_data.length) {
                const firstEvent = eventItem.event_data[eventIndex];
                const firstEventPoints = firstEvent.event_products_cnt.length + (firstEvent.notes ? firstEvent.notes.length : 0);
                currentY = renderEvent(firstEvent, eventItem.events_name[eventIndex], 1.2, currentY+0.15);    
                eventIndex += 1;
    
                if (eventIndex < eventItem.event_data.length) {
                    const secondEvent = eventItem.event_data[eventIndex];
                    const secondEventPoints = secondEvent.event_products_cnt.length + (secondEvent.notes ? secondEvent.notes.length : 0);
    
                    if (firstEventPoints < 8 && secondEventPoints < 8) {
                        renderEvent(secondEvent, eventItem.events_name[eventIndex], pdf.internal.pageSize.width / 2 + 0.5, secondEventY);
                        eventIndex += 1;
                    }
                }
    
                if (eventIndex < eventItem.event_data.length) {
                    addNewPage();
                    currentY = titleY;
                    currentY = renderTitle(eventItem.day_info, currentY);
                }
            }
        });
    }
    
    async preProdPage(value){
        const { pdf } = this;
        
        const addNewPage = () => {
            pdf.addPage();
            this.isWedding && pdf.addImage(bgImg, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
            !this.isWedding && pdf.setFillColor(251, 247, 244);
            !this.isWedding && pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
            pdf.setFontSize(42);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            this.isWedding && pdf.setTextColor(...(Platform.isEG ? [159, 120, 102] : [134, 126, 116]));
            !this.isWedding && pdf.setTextColor(...this.textColor);
            pdf.text(title, 1.2, 1.2);
        }
    
        const bgImg = await this.loadImage(Platform.isEG ? prePost : prePostWG);
        const title = 'PRE & POST PRODUCTION TEAM';
    
        addNewPage();
    
        let currentY = 2; 
        let itemsOnPage = 0; 
    
        value.forEach((e) => {
            if (value.length > 9  && itemsOnPage >= value.length/2) {
                addNewPage();
                currentY = 2; 
                itemsOnPage = 0;
            }
    
            pdf.setFontSize(20);
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            this.isWedding && pdf.setTextColor(255, 255, 255);
    
            const textLine = `• ${e.count} ${e.name}`;
            pdf.text(textLine, 1.2, currentY, { align: 'left' });
    
            currentY += 0.6;
    
            itemsOnPage++;
        });
    }

    async deliverablePage(value){
        const { pdf } = this;
    
        const addNewPage = () => {
            pdf.addPage();
            pdf.setFillColor(...(this.isWedding ? [251, 247, 244] : this.bgColor));
            pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
            pdf.setFontSize(42);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.setTextColor(...this.textColor);
            pdf.text(title, 1.2, 1.2);
        }
    
        const title = 'DELIVERABLES';
    
        addNewPage();
    
        let currentY = 2;
        let itemsOnPage = 0;
    
        value.forEach((e) => {
            if (value.length > 9  && itemsOnPage >= value.length/2) {
                addNewPage();
                currentY = 2;
                itemsOnPage = 0;
            }
    
            const lname = `• ${e.display_data.display_name.lname}`;
            const rname = e.display_data.display_name.rname && `:  ${e.display_data.display_name.rname}`;
    
            pdf.setFontSize(22);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            const lnameWidth = pdf.getTextWidth(lname);
            pdf.text(lname, 1.2, currentY);
    
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.text(rname, 1.2 + lnameWidth, currentY);
    
            currentY += 0.6;
            itemsOnPage++;
        });
    }

    async delDurPage(value) {
        const { pdf } = this;

        const addNewPage = () => {
            pdf.addPage();
            this.isWedding && pdf.addImage(bgImg, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
            !this.isWedding && pdf.setFillColor(251, 247, 244);
            !this.isWedding && pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
            pdf.setFontSize(42);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            this.isWedding && pdf.setTextColor(...(Platform.isEG ? [159, 120, 102] : [134, 126, 116]));
            !this.isWedding && pdf.setTextColor(...this.textColor);
            pdf.text(title, 1.2, 1.2);
        }
    
        const bgImg = await this.loadImage(Platform.isEG ? delDiv : delDivWG);
        const title = 'DELIVERY DURATION';
    
        addNewPage();
        const textLines = [
            "* The delivery duration starts upon receipt of full payment for the amount due post-shoot.",
            "* Delivery timelines may vary a bit in peak wedding season."
        ]
        let currentY = 2;
        let itemsOnPage = 0;
        const padding = 1.2;
        const pageWidth = pdf.internal.pageSize.width;

        value.delivery_data.forEach((e) => {
            if (itemsOnPage >= 5) {
                pdf.setFontSize(16);
                textLines.forEach(line => {
                    pdf.text(line, padding, currentY);
                    currentY += 0.5;
                });
                addNewPage();
                currentY = 2;
                itemsOnPage = 0;
            }

            const lname = `• ${e.prod_name}`;
            const rname = `  ${e.duration}`;

            pdf.setFontSize(22);
            this.isWedding && pdf.setTextColor(255, 255, 255);
            const rnameWidth = pdf.getTextWidth(rname);
    
            pdf.text(lname, padding, currentY);
    
            pdf.text(rname, pageWidth - padding - rnameWidth - 2, currentY);
            if(e.help_text){
                currentY += 0.3
                pdf.setFontSize(14);
                pdf.text(`(${e.help_text})`, padding+0.2, currentY);
            }
            currentY += 0.6;
            itemsOnPage++;
        });

        pdf.setFontSize(16);
        this.isWedding && pdf.setTextColor(255, 255, 255);

        textLines.forEach(line => {
            pdf.text(line, padding, currentY);
            currentY += 0.5;
        });

        if(value.duration_strings.length >0){
            pdf.setFontSize(16);
            this.isWedding && pdf.setTextColor(255, 255, 255);
            value.duration_strings.forEach((e) => {
                const wrappedLines = pdf.splitTextToSize(e, pageWidth / 1.5)
                pdf.text(`*`, padding, currentY);
                wrappedLines.forEach((line) => {
                    pdf.text(`${line}`, padding+0.15, currentY);
                    currentY += 0.5;
                })
            });
        }
    }

    async exclusivePage() {
        const { pdf } = this;
        pdf.addPage();

        pdf.setFillColor(...this.bgColor);
        pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');

        const padding = 1.2;
        const lines = [
            { text: `Exclusively Available at ${this.company}`, fontSize: 40},
            { text: `The deliverables would be made available in \n ${this.company} Website with features like`, fontSize: 32},
            { text: 'AI Facial Detection and Smart Sharing.', fontSize: 36},
            { text: 'You can share your sweet memories with relatives and family via our website anytime, anywhere.', fontSize: 28},
        ];

        const mainLineSpacing = 0.80; 
        const wrappedLineSpacing = 0.3; 

        let totalHeight = 4.5;
        const startY = (pdf.internal.pageSize.height - totalHeight)/2;

        let currentY = startY;
        pdf.setTextColor(...this.textColor);

        lines.forEach((line, index) => {
            pdf.setFontSize(line.fontSize);
            if(index%2==0){
                pdf.addFont(montserratMedium, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
            }else{
                pdf.addFont(montserrat, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
            }
            const pageWidth = pdf.internal.pageSize.width - 2 * padding;
            const textLines = pdf.splitTextToSize(line.text, pageWidth);

            textLines.forEach((textLine, lineIndex) => {
                const textWidth = pdf.getTextWidth(textLine);
                const textX = (pdf.internal.pageSize.width - textWidth) / 2;
                pdf.text(textLine, textX, currentY);
                currentY += (line.fontSize / 72);

                if (lineIndex < textLines.length - 1) {
                    currentY += wrappedLineSpacing;
                }
            });

            if (index < lines.length - 1) {
                currentY += mainLineSpacing;
            }
        });
    }

    async pricingPage(value) {
        const { pdf } = this;
        pdf.addPage();
    
        pdf.setFillColor(251, 247, 244);
        pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
    
        const title = 'QUOTATION';
        pdf.setFontSize(42);
        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.setTextColor(...this.textColor);
        const textWidth = pdf.getTextWidth(title);
        const x = (pdf.internal.pageSize.width - textWidth) / 2;
        pdf.text(title, x, 1.2);
    
        let currentY = 2;
        let itemsOnPage = 0;
        const mainLineSpacing = 0.80;
        const wrappedLineSpacing = 0.3;
    
        value.forEach((e, index) => {
            if (!this.data.estimation_details.is_final ? itemsOnPage >= (this.data.estimation_details?.advance_url ? 4 : 6) : itemsOnPage >= 7) {
                pdf.addPage();
                currentY = 2;
                itemsOnPage = 0;
            }
    
            const lname = `• ${e.display_name.lname}:`;
            const rname = `\u20B9 ${e.display_name.cost}`;
            const color = e.display_name?.web_color_code
            const textDecoration = e.display_name?.text_transform
    
            pdf.setFontSize(30);
            color ? pdf.setTextColor(color) : pdf.setTextColor(...this.textColor);
            const pageWidth = pdf.internal.pageSize.width;
            const lnameX = (pageWidth / 2 - 4)
            const rnameX = (pageWidth / 2 + 4) - pdf.getTextWidth(rname); 
            const lnameWidth = pdf.getTextWidth(lname);
            const rnameWidth = pdf.getTextWidth(rname);

            if(e.display_name?.bold){
                pdf.addFont(montserratMedium, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
            }else{
                pdf.addFont(montserrat, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
            }
            pdf.text(lname, lnameX, currentY);
            pdf.text(rname, rnameX, currentY);
            if(textDecoration){
                pdf.setDrawColor(187, 10, 0)
                pdf.setLineWidth(0.04)
                pdf.line(lnameX + 0.2, currentY - 0.12, lnameX + lnameWidth, currentY - 0.12)
                pdf.setDrawColor(187, 10, 0)
                pdf.setLineWidth(0.04)
                pdf.line(rnameX, currentY - 0.12, rnameX + rnameWidth, currentY - 0.12)
            }
            if(e.display_substr?.name){
                pdf.setFontSize(16);
                pdf.addFont(montserratMedium, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
                currentY += 0.3;
            }
            e.display_substr?.name && pdf.text(`(${e.display_substr.name})`, lnameX+0.2, currentY)
    
            currentY += 0.6;
            itemsOnPage++;
        });

        if(this.data.estimation_details?.advance_url){
            const pageWidth = pdf.internal.pageSize.width;
            pdf.setFontSize(20);
                
            const text = 'Pay Advance\u2192';
            const textWidth = pdf.getTextWidth(text);
            const textX = (pageWidth - textWidth) / 2;
            const textY = currentY;
            const paddingX = 0.1;
            const paddingY = 0.2;
        
            pdf.textWithLink(text, textX, textY, {
                url: this.data.estimation_details.advance_url
            });
        
            const textHeight = 0.2;
        
            const buttonX = textX - paddingX;
            const buttonY = textY - textHeight + paddingY / 2 - 0.2;
            const buttonWidth = textWidth + paddingX * 2;
            const buttonHeight = textHeight + paddingY;
            const borderRadius = 0.1;
            pdf.setLineWidth(0.02);
            pdf.setDrawColor(...this.textColor)
            pdf.roundedRect(buttonX, buttonY, buttonWidth, buttonHeight, borderRadius, borderRadius);
            currentY += 0.7;
        }

        if(this.data.estimation_details.hasOwnProperty('inclusions_data') && this.data.estimation_details['inclusions_data'].length > 0){
            pdf.setFontSize(20);
            pdf.setTextColor(...this.textColor);
            currentY += 0.1;
            value.length == 6 && pdf.addPage()
            if(value.length == 6)currentY = 1.2
            this.data.estimation_details['inclusions_data'].forEach((e) => {
                const text = e.desc
                const dataWidth = pdf.getTextWidth(text)
                pdf.addFont(montserrat, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
                pdf.text(`*${e.desc}`, (pdf.internal.pageSize.width - dataWidth) / 2, currentY);
                currentY += 0.4;
            });
        }
    
        if (!this.data.estimation_details.is_final) {
            const textLines = [
                "* This proposal does not guarantee that teams and dates are blocked, availability would be subjected to the date of confirmation."
            ];
    
            pdf.setFontSize(20);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.setTextColor(...this.textColor);
            currentY += 0.2;
            textLines.forEach((line, index) => {
                pdf.setFontSize(20);
                const pageWidth = pdf.internal.pageSize.width;
                const wrappedLines = pdf.splitTextToSize(line, pageWidth / 1.5);
                wrappedLines.forEach((wrappedLine, lineIndex) => {
                    const lineWidth = pdf.getTextWidth(wrappedLines[lineIndex]);
                    pdf.text(wrappedLine, (pageWidth-lineWidth)/2, currentY);
                    currentY += (20 / 72)-0.2;
    
                    if (lineIndex < wrappedLines.length - 1) {
                        currentY += wrappedLineSpacing;
                    }
                });
    
                if (index < textLines.length - 1) {
                    currentY += mainLineSpacing;
                }
            });
        }
    }

    async addOnPage(value) {
        const { pdf } = this;
    
        const addNewPage = () => {
            pdf.addPage();
            pdf.setFillColor(...this.bgColor);
            pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
            pdf.setFontSize(42);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.setTextColor(...this.textColor);
            pdf.text(title, 1.2, 1.2);
        }
    
        const title = 'Add-ons';
    
        addNewPage();
    
        let currentY = 2;
        let itemsOnPage = 0;
        const padding = 1.2;
    
        value.forEach((e) => {
            if (value.length > 9  && itemsOnPage >= value.length/2) {
                addNewPage();
                currentY = 2;
                itemsOnPage = 0;
            }
    
            const lname = `• ${e.name}:`;
            const rname = `  \u20B9 ${e.cost}`;
    
            pdf.setFontSize(22);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            const rnameWidth = pdf.getTextWidth(rname);
            const pageWidth = pdf.internal.pageSize.width;
    
            pdf.text(lname, padding, currentY);
    
            pdf.text(rname, pageWidth - padding - rnameWidth, currentY);
    
            currentY += 0.6;
            itemsOnPage++;
        });
    }

    async installmentPage(value) {
        const { pdf } = this;

        const addNewPage = async () => {
            pdf.addPage();
            pdf.setFillColor(251, 247, 244);
            pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
            pdf.setFontSize(42);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.setTextColor(...this.textColor);
            pdf.text(title, 1.2, 1.2);
        }

        const title = 'PAYMENT POLICY';

        await addNewPage();

        let currentY = 2;
        let itemsOnPage = 0;
        const paddingX = 1.2;
        const paddingRight = 1.2;

        value.forEach((installment) => {
            if (itemsOnPage > 6) {
                addNewPage();
                currentY = 2;
                itemsOnPage = 0;
            }
            const installmentTitle = `• ${installment.display_name.lname} ${installment.display_name.percent} ${installment.display_name.desc} :`;
            const installmentAmount = `\u20B9 ${installment.display_name.cost}`;
            
            pdf.setFontSize(26);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            const installmentTitleWidth = pdf.getTextWidth(installmentTitle)
            const pageWidth = pdf.internal.pageSize.width;
            const amountWidth = pdf.getTextWidth(installmentAmount);
            
            const amountX = pageWidth - amountWidth - paddingRight;
            
            pdf.text(installmentTitle, paddingX, currentY);
            if(installment.display_name.is_paid){
                const paidText = installment?.display_name?.is_paid ? 'Paid' : '';
                pdf.setFontSize(18);
                pdf.setFont("helvetica", "italic"); 
                pdf.text(paidText, paddingX+installmentTitleWidth+0.07, currentY);
                pdf.addFont(montserrat, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
                pdf.setFontSize(26);
            }
            pdf.text(installmentAmount, amountX, currentY);

            if(installment.display_name.due_date){
                pdf.setFontSize(14);
                pdf.addFont(montserratMedium, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
                pdf.text(`${installment.display_name.due_date}`, paddingX+0.25, currentY+0.25);
                pdf.addFont(montserrat, 'montserrat', 'normal');
                pdf.setFont('montserrat', 'normal');
                pdf.setFontSize(26);
            }
            if (installment.display_name.pay_link) {
                pdf.setFontSize(20);
                
                const text = 'Pay\u2192';
                const textX = installmentTitleWidth + 1.5;
                const textY = currentY;
                const paddingX = 0.1;
                const paddingY = 0.2;
            
                pdf.textWithLink(text, textX, textY, {
                    url: installment.display_name.pay_link
                });
            
                const textWidth = pdf.getTextWidth(text);
                const textHeight = 0.2;
            
                const buttonX = textX - paddingX;
                const buttonY = textY - textHeight + paddingY / 2 - 0.2;
                const buttonWidth = textWidth + paddingX * 2;
                const buttonHeight = textHeight + paddingY;
                const borderRadius = 0.1;
                pdf.setLineWidth(0.02);
                pdf.setDrawColor(...this.textColor)
                pdf.roundedRect(buttonX, buttonY, buttonWidth, buttonHeight, borderRadius, borderRadius);

            }
            
            currentY += 0.9;
            itemsOnPage++;
        });
    }

    async CancellationPolicyPage(value) {
        const { pdf } = this;
        pdf.addPage();
    
        pdf.setFillColor(251, 247, 244);
        pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
    
        const title = 'Cancellation Policy';
        pdf.setFontSize(42);
        pdf.setTextColor(...this.textColor);
        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        const paddingX = 1.2;
        pdf.text(title, paddingX, 1.2);
    
        pdf.setFontSize(20);
        pdf.setTextColor(...this.textColor);
        pdf.addFont(montserrat, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.text('In case of any cancellation by the client, the following applies:', paddingX, 1.8);
    
        const header = ['Cancellation Time', 'Cancellation Fee'];
        const pageWidth = pdf.internal.pageSize.width;
        const contentWidth = pageWidth - 2 * paddingX;
        const colWidths = [contentWidth / 2, contentWidth / 2];
        let startY = 2.5;
        const rowHeight = 0.8;
    
        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.setFontSize(24);
        let colX = paddingX;
    
        pdf.setFillColor(251, 247, 244);
        pdf.rect(paddingX, startY - 0.5, contentWidth, rowHeight, 'F');
    
        for (let i = 0; i < header.length; i++) {
            const headerText = header[i];
            const textWidth = pdf.getTextWidth(headerText);
            const centerX = colX + (colWidths[i] - textWidth) / 2;
            pdf.text(headerText, centerX, startY);
            colX += colWidths[i];
        }
    
        pdf.setDrawColor(169, 160, 152); 
        pdf.setLineWidth(0.01);
        pdf.rect(paddingX, startY - 0.5, contentWidth, rowHeight);
    
        const separationX = paddingX + colWidths[0];
        pdf.setDrawColor(169, 160, 152); 
        pdf.setLineWidth(0.01);
        pdf.line(separationX, startY - 0.5, separationX, startY + value.length * rowHeight + 0.3);
    
        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.setFontSize(20);
        startY += rowHeight;
    
        value.forEach(rowData => {
            colX = paddingX;
    
            const row = [rowData.time_str, rowData.display_fee];
    
            row.forEach((cellText, index) => {
                const textWidth = pdf.getTextWidth(cellText);
                const centerX = colX + (colWidths[index] - textWidth) / 2;
                pdf.text(cellText, centerX, startY);
                colX += colWidths[index];
            });
    
            pdf.setDrawColor(169, 160, 152); 
            pdf.setLineWidth(0.01);
            pdf.rect(paddingX, startY - 0.5, contentWidth, rowHeight);
    
            startY += rowHeight;
        });
    }

    async whyPage() {
        const { pdf } = this;
        pdf.addPage();
    
        const bgImg = await this.loadImage(Platform.isEG ? whyEG : whyWG);
        pdf.addImage(bgImg, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
    
        pdf.setFontSize(42);
        pdf.setTextColor(...(Platform.isEG ? [159, 120, 102] : [134, 126, 116])); 
    
        const title = Platform.isEG ? 'WHY EVENTGRAPHIA?' : 'WHY WEDGRAPHIA?';
        pdf.addFont(montserratMedium, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.text(title, 1.2, 1.2);

        pdf.setFontSize(20);
        pdf.addFont(montserrat, 'montserrat', 'normal');
        pdf.setFont('montserrat', 'normal');
        pdf.setTextColor(255, 255, 255);
        const textLines = [
            `${this.company} is a Premium Event Photography Service space. We provide seamless`,
            "end-to-end solutions right from booking inquiry to the delivery of photos, videos and",
            "albums, all digitally using our web app.",
            "",
            "• India's Largest Event Photography Company",
            "• Serving 175+ Cities",
            "• 210+ Professionals",
            "• Covered 500+ Weddings",
            "• State of the art Equipments",
            "• Customers - BMW, TAJ, Uber, Dominos, Harley Davidson, Hello English, Oyo Rooms",
            "  and many more."
        ];

        let currentY = 2;
        textLines.forEach(line => {
            pdf.text(line, 1.2, currentY, { align: 'left' });
            currentY += 0.5;
        })
    }
    
    async TermsPage() {
        const { pdf } = this;
        const setupPageTemplate = () => {
            pdf.setFillColor(251, 247, 244);
            pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
    
            const title = 'Terms & Conditions';
            pdf.setFontSize(42);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            const titleX = 1.2;
            pdf.text(title, titleX, 1);
        };
    
        pdf.addPage();
        setupPageTemplate();
    
        const terms = termsConditions(true)
    
        let currentY = 1.6;
        let termCounter = 0;
        
        const pageWidth = pdf.internal.pageSize.width;
        const contentWidth = pageWidth - 2.4;
    
        terms.forEach(term => {
            pdf.setFontSize(20);
            pdf.setTextColor(...this.textColor);
            pdf.addFont(montserratMedium, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            const headingWidth = pdf.getTextWidth(term.heading);
            const startX = 1.2;
    
            const firstLineTextWidth = contentWidth - headingWidth - 0.4;
            
            const firstLineWrappedText = pdf.splitTextToSize(term.text, firstLineTextWidth);
            
            pdf.text(`• ${term.heading}`, startX, currentY);
            pdf.addFont(montserrat, 'montserrat', 'normal');
            pdf.setFont('montserrat', 'normal');
            pdf.text(firstLineWrappedText[0], startX + headingWidth + 0.1, currentY);
            
            const remainingText = term.text.replace(firstLineWrappedText[0], "").trim();
            
            if (remainingText) {
                const remainingWrappedText = pdf.splitTextToSize(remainingText, contentWidth);
                for (let i = 0; i < remainingWrappedText.length; i++) {
                    if (termCounter >= 11) {
                        pdf.addPage();
                        setupPageTemplate();
                        currentY = 1.25; 
                        termCounter = 0;
                    }
                    pdf.setFontSize(20);
                    pdf.addFont(montserrat, 'montserrat', 'normal');
                    pdf.setFont('montserrat', 'normal');
                    currentY += 0.35;
                    pdf.text(remainingWrappedText[i], startX, currentY);
                    termCounter++; 
                }
            }
            currentY += 0.7; 
        });

        const today = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = today.toLocaleDateString('en-US', options);
        const generatedText = `Generated on: ${formattedDate}`
        const generatedTextWidth = pdf.getTextWidth(generatedText);
        const xPosition = (pageWidth - generatedTextWidth + 1.4)/2;
        currentY = 7;
        pdf.setFontSize(14);
        pdf.text(generatedText, xPosition, currentY);
    }
    
    
    async generateAndSavePDF() {
        const quoteData = this.data.estimation_details;
    
        const pdfProgress = document.createElement('div');
        pdfProgress.className = 'overlayLoader';
    
        const progressContent = document.createElement('div');
        progressContent.style.display = 'flex'
        progressContent.style.alignItems = 'center'
        progressContent.style.justifyContent = 'center'
        progressContent.className = 'progressContent';
        progressContent.innerHTML = `
            <div style="color: white; font-size: 30px; display: flex; flex-direction: column; align-items: center; margin-bottom: 40vh">
                <div class="loader"></div>
                <div style="margin-top: 10px">
                    <span style="margin-right:5px">Generating PDF:</span>
                    <span class="percentage">0%</span>
                </div>
            </div>
        `;
    
        pdfProgress.appendChild(progressContent);
    
        const bodyDiv = document.getElementsByTagName('body')[0];
        bodyDiv.style.overflow = 'hidden';
        bodyDiv.appendChild(pdfProgress);
    
        const totalTasks = 13;
        let completedTasks = 0;
    
        const updateProgress = () => {
            completedTasks++;
            const progress = Math.round((completedTasks / totalTasks) * 100);
            
            const percentageText = progressContent.querySelector('.percentage');
            percentageText.textContent = `${progress}%`;
    
            if (progress === 100) {
                bodyDiv.removeChild(pdfProgress);
                bodyDiv.style.overflow = 'auto';
            }
        };
    
        await this.coverPage();
        updateProgress();
    
        if (Platform.isEG && this.isWedding) {
            await this.awardPage();
            updateProgress();
        }

        if(!this.isWedding){
            await this.nonWeddingWhy();
            updateProgress();
        }
    
        if (quoteData.hasOwnProperty('estimation_data') && quoteData.estimation_data.length>0) {
            await this.schedulePage(quoteData.estimation_data);
            updateProgress();
        }
    
        if (quoteData.hasOwnProperty('estimation_data') && quoteData.estimation_data.length>0) {
            await this.eventsPage(quoteData.estimation_data);
        }
        updateProgress();
    
        if (quoteData.hasOwnProperty('production_team') && quoteData.production_team.length>0) {
            await this.preProdPage(quoteData.production_team);
        }
        updateProgress();
    
        if (quoteData.hasOwnProperty('booking_products') && quoteData.booking_products.length>0) {
            await this.deliverablePage(quoteData.booking_products);
        }
        updateProgress();
    
        if (quoteData.hasOwnProperty('delivery_duration') && quoteData.delivery_duration.delivery_data.length>0) {
            await this.delDurPage(quoteData.delivery_duration);
        }
        updateProgress();
    
        await this.exclusivePage();
        updateProgress();
    
        if (quoteData.hasOwnProperty('preview_list') && quoteData.preview_list.length>0) {
            await this.pricingPage(quoteData.preview_list);
        }
        updateProgress();
    
        if (quoteData.hasOwnProperty('additional_products') && quoteData.additional_products.length>0) {
            await this.addOnPage(quoteData.additional_products);
        }
        updateProgress();
    
        if (quoteData.hasOwnProperty('installment_data') && quoteData.installment_data.length>0) {
            await this.installmentPage(quoteData.installment_data);
        }
        updateProgress();
    
        if (quoteData.hasOwnProperty('cancellation_policy') && quoteData.cancellation_policy.length>0) {
            await this.CancellationPolicyPage(quoteData.cancellation_policy);
        }
        updateProgress();

        this.isWedding && await this.whyPage();
        updateProgress();

        await this.TermsPage();
        updateProgress();
    
        this.pdf.save(`${this.data.estimation_details.quot_title}.pdf`);
        updateProgress();
    }
    
}

export function GeneratePdf(data, isWedding) {
    const pdfGen = new PDFGenerator(data, isWedding);
    pdfGen.generateAndSavePDF();
}
