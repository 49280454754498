import React, { useEffect } from "react";
import "./Toast.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Toast = ({ message, duration = 3000, onClose }) => {
  useEffect(() => {
    // Set a timer to call onClose after the duration
    const timer = setTimeout(() => {
      if (onClose) {
        onClose(); // Notify parent to remove the toast
      }
    }, duration);

    // Cleanup the timer on unmount
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="toast" onClick={onClose}>
      {message.title}{" "}{message.hasOwnProperty('action') && 
      <span onClick={()=>{window.location.href=message.action.url}} style={{color: message.action.color, textDecoration:"underline", fontWeight:"900", paddingLeft:"50px", cursor:"pointer"}}>{message.action.title}</span>}
      <FontAwesomeIcon icon={faXmark} size="lg" style={{color: "#ffffff",cursor:"pointer", paddingLeft:"10px"}} />
    </div>
  );
};

export default Toast;
