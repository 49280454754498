import React from 'react'
import { get_platform_bg_color } from '../../script/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const FloatingBar = ({ data, index }) => {
    const queryParams = new URLSearchParams(window.location.search);

    const closeAdminToggle = () => {
        document.getElementsByClassName('adminSwitchOverlay')[index].style.display = 'none'
    }
    const viewAsAdmin = () => {
        window.location.href = data?.btn?.url
    }

    let initialBottomGap = 8;
    let initailFloatingBarGap = 7;

    if(window.innerWidth < 1200) {
        initialBottomGap = 12;
    }

    if(window.innerWidth < 600) {
        initialBottomGap = 13;
    }

    const bottom_gap = initialBottomGap + (index * initailFloatingBarGap);

    return (
        <div>
            {data && <div className="adminSwitchOverlay" style={{background: data?.bg_color || get_platform_bg_color(), bottom: `${bottom_gap}%`}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {data?.title && <div style={{color: data?.text_color}}>{data?.title}</div>}
                    {data?.btn && <div style={{display:"flex",padding:"0 20px", justifyContent:"space-around" }}>
                        <div className="toggleText" onClick={viewAsAdmin} style={{backgroundColor: data?.btn?.bg_color, color: data?.btn?.text_color}} >{data?.btn?.text}</div>
                    </div>}
                    {data?.can_cancel && <div><FontAwesomeIcon icon={faXmark} onClick={closeAdminToggle} style={{cursor:"pointer"}}/></div>}
                </div>
            </div>}
        </div>
        
    )
}

export default FloatingBar