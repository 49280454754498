import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import { getLoginPage, getWhatsappShareUrl, getAuthToken, getAPIUrl, isMobile, defaultQuality, isAdmin, get_logo, catchError } from "../script/util.js";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copy from "clipboard-copy";
import Popup from "../components/Popup/Popup.js"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCaretRight, faGear, faCaretLeft, faDownload, faShareNodes, faCopy, faRotateLeft, faBackward, faForward, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';


const VideoPlayer = (getData) => {
    const [data, setData] = useState(getData.data)
    const [videoUrl, setVideoUrl] = useState()
    const [openMenu, setOpenMenu] = useState(false)
    const [openQuality, setOpenQuality] = useState(false)
    const [openRate, setOpenRate] = useState(false)
    const playBackRef = useRef(null)
    const [userActive, setUserActive] = useState(true)
    const downloadRef = useRef(null)
    const currRef = useRef(null)
    const retryRef = useRef(null)
    const forwardRef = useRef(null)
    const backwardRef = useRef(null)
    const [isPaused, setIsPaused] = useState(true);
    const videoRef = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [overlay, setOverlay] = useState(false)
    const [shareUrl, setShareUrl] = useState();
    const [linkCopied, setLinkCopied] = useState(false);
    const playerRef = useRef(null);
    const defQuality = defaultQuality()
    const [runQuality, setRunQuality] = useState(defQuality)
    const [orientation, setOrientation] = useState(
        window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape"
    );
    const [playbackPosition, setPlaybackPosition] = useState(0);
    const [playbackRate, setPlaybackRate] = useState('Normal')
    const [completedTime, setCompletedTime] = useState(0);
    const [qualityShortName, setQualityShortName] = useState()
    const videoOrientation = useRef();

    useEffect(()=>{
        setData(getData.data)
    },[])

    useEffect(()=>{
      data.url_list.map((e)=>{
        if(e.quality == runQuality){
          setQualityShortName(e.short_name)
        }
      })
    },[runQuality])

    useEffect(() => {
        if(videoRef.current && data){
          playerRef.current = videojs(videoRef.current, {
            autoplay: false,
            controls: true,
            sources: [{ src: getData.videoUrl, type: 'video/mp4' }],
            techOrder: ['html5'], 
              html5: {
                playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2], 
              },
          });
    
          playerRef.current?.ready(() => {
            playerRef.current?.controlBar.addChild('PlaybackRateMenuButton', {}, 6);
            const bar =  playerRef.current?.controlBar.el();
            const fullscreenButton = bar.querySelector('.vjs-picture-in-picture-control');
            const playDefault = bar.querySelector('.vjs-play-control')
            const volumeDefault = bar.querySelector('.vjs-volume-panel')
            if(isMobile())playDefault.style.display = 'none'
            bar.insertBefore(playBackRef.current,fullscreenButton);
            downloadRef.current && bar.insertBefore(downloadRef.current,fullscreenButton);
            retryRef.current && bar.insertBefore(retryRef.current,playDefault);
            backwardRef.current && bar.insertBefore(backwardRef.current,playDefault);
            forwardRef.current && bar.insertBefore(forwardRef.current,volumeDefault);
          });
          playerRef.current.on('play', ()=>{
            setIsPaused(false)
          });
          playerRef.current.on('pause', ()=>{
            setIsPaused(true)
          });

          playerRef.current.on('loadedmetadata', function() {
            const x = document.getElementsByClassName('vjs-text-track-display')
            var width = x[0].offsetWidth;
            var height = x[0].offsetHeight;
            if(width>height){
              videoOrientation.current = 'landscape'
            }else {
              videoOrientation.current = 'portrait'
            }
          });

          playerRef.current.on('timeupdate', () => {
            const currentTime = playerRef.current?.currentTime();
            setCompletedTime(currentTime);
            const bar =  playerRef.current?.controlBar.el();
            const minutes = Math.floor(currentTime / 60);
            const remainingSeconds = Math.floor(currentTime % 60);
            const secondsString = remainingSeconds.toString().padStart(2, '0');
            const remainingTime = document.getElementsByClassName('vjs-remaining-time')
            if(remainingTime[0])remainingTime[0].style.display = 'none'
            const qltyDiv = document.getElementsByClassName('qualityDiv')[0]
            const currTime = document.getElementsByClassName('currentTime')
            const totalDuration = document.getElementsByClassName('vjs-time-control')
            if(isMobile() && window.screen.orientation.type === 'portrait-primary'){
              bar.style.bottom = '15%'
              currTime[0].style.display = 'flex'
              for (let i = 0; i < totalDuration.length - 1; i++) {
                totalDuration[i].style.display = 'none';
              }
            }else{
              bar.style.bottom = '3%'
              if(currTime[0])currTime[0].style.display = 'none'
              for (let i = 0; i < totalDuration.length - 1; i++) {
                totalDuration[i].style.display = 'block';
              }
            }
            currRef.current && bar.insertBefore(currRef.current, qltyDiv)
            if(currTime[0])currTime[0].textContent = `${minutes}:${secondsString}`
          });
    
          playerRef.current?.on('userinactive', ()=>{
            setTimeout(()=>{
              setOpenQuality(false)
              setOpenRate(false)
              setOpenMenu(false)
              if(!playerRef.current?.paused()){
                setUserActive(false)
                setOverlay(false)
              }
            },2000)
          })
          document.addEventListener('mousemove', debounce(handleMouseMove, 100));
          document.addEventListener('click', handleMouseClick);
          document.addEventListener('touchstart', debounce(handleMouseMove, 100));
          return () => {
            document.removeEventListener('mousemove', debounce(handleMouseMove, 100));
            document.removeEventListener('click', debounce(handleMouseClick, 100));
            document.addEventListener('touchstart', handleMouseMove);
            if (!playerRef.current) {
              playerRef.current?.dispose();
            }
          };
        } 
      }, [data, playerRef.current]);

    const showMenu = () => {
        setOpenMenu((prev)=>!prev)
        setOpenQuality(false)
        setOpenRate(false)
    }

    const manualPlay = () => {
        if(playerRef.current?.paused()){
        setIsPaused(false)
        playerRef.current?.play()
        setTimeout(()=>{
            setOpenQuality(false)
            setOpenRate(false)
            setOpenMenu(false)
            if(!playerRef.current?.paused()){
            setUserActive(false)
            setOverlay(false)
            }
        },2000)
        }else{
        setIsPaused(true)
        playerRef.current?.pause()
        }
    }


    useEffect(()=>{
        if(data){
          for(let i = 0; i<data.url_list?.length; i++){
            if(data.url_list[i].quality == runQuality){
              setVideoUrl(data.url_list[i].url)
              playerRef.current?.src(data.url_list[i].url)
              playerRef.current?.load()
              playerRef.current?.currentTime(playbackPosition);
              playerRef.current?.play()
            }
            setOpenQuality(false)
            playbackPosition !==0 && setOpenMenu(true)
          }
        }
      },[runQuality])


    const headers = { Authorization: getAuthToken() };
    const inputData = { data_id_list: [`${getData.id}`] };

    const ShareCall = () => {
        playerRef.current?.pause()
        setIsPopupOpen(true)
        // setIsOpen(false);
        axios
          .post(getAPIUrl(`/api/share/url/${getData.bookingPK}/`), inputData, { headers })
          .then(function (res) {
            setShareUrl(res.data.share_url);
          })
          .catch((error) => {
            catchError(error);
          });
      };

    const restartVideo = () => {
        if (playerRef.current) {
        playerRef.current?.currentTime(0); // Seek to the beginning
        playerRef.current?.play(); // Start playing
        }
    }

    const skipForward = () => {
        if (playerRef.current) {
        const currTime = playerRef.current?.currentTime();
        playerRef.current?.currentTime(currTime+10);
        }
    }

    const skipBackward = () => {
        if (playerRef.current) {
        const currTime = playerRef.current?.currentTime();
        playerRef.current?.currentTime(currTime-10);
        }
    }

    const copyLinkUrl = () => {
        setLinkCopied(true);
        copy(shareUrl);
        setIsPopupOpen(false)
        setTimeout(() => {
        setLinkCopied(false);
        }, 2000);
    };

    

    useEffect(() => {
        const handleFullscreenChange = () => {
          if (document.fullscreenElement) {
            if (window.screen.orientation.lock) {
              videoOrientation.current == 'landscape' && window.screen.orientation.lock('landscape').catch(err => console.error('Error locking orientation:', err));
            }
          } else {
            if (window.screen.orientation.unlock) {
              window.screen.orientation.unlock();
            }
          }
        };
          document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
          document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
      }, []);

    useEffect(() => {
        const handleOrientationChange = () => {
          setOrientation(window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape");
          const currTime = document.getElementsByClassName('currentTime')
            const totalDuration = document.getElementsByClassName('vjs-time-control')
          if(isMobile() && window.screen.orientation.type === 'portrait-primary'){
            currTime[0].style.display = 'flex'
            for (let i = 0; i < totalDuration.length - 1; i++) {
              totalDuration[i].style.display = 'none';
            }
          }else{
            currTime[0].style.display = 'none'
            for (let i = 0; i < totalDuration.length - 1; i++) {
              totalDuration[i].style.display = 'block';
            }
          }
          const bar = document.getElementsByClassName('vjs-control-bar')[0]
          if(isMobile() && window.screen.orientation.type !== 'portrait-primary'){
            bar.style.bottom = '3%'
          }else{
            bar.style.bottom = '15%'
          }
        };
    
        window.addEventListener('resize', handleOrientationChange);
    
        return () => {
          window.removeEventListener('resize', handleOrientationChange);
        };
      }, []);

    useEffect(()=>{
        if(isMobile()){
          setOverlay(true)
        }else{
          setOverlay(false)
        }
      },[])

    useEffect(()=>{
        const ele = document.getElementsByClassName('shareDiv')
        const over = document.getElementsByClassName('overlayMobile')
        if(ele[0] && over[0]){
          if(userActive){
            ele[0].style.visibility = 'visible'
            over[0].style.visibility = 'visible'
          }else{
            ele[0].style.visibility = 'hidden'
            over[0].style.visibility = 'hidden'   
          }
        }
      },[userActive])

    const videoDownload = () => {
        setOpenMenu(false)
        const tempAnchor = document.createElement('a')
        tempAnchor.href = getData.videoUrl
        tempAnchor.setAttribute('download', '');
        tempAnchor.click();
    }

    const currQuality = (e) => {
        setRunQuality(e.target.value)
    }


  const qualityOptionSwitch = () => {
    const currentTime = playerRef.current?.currentTime();
    setPlaybackPosition(currentTime);
  };

  const handleMouseMove = () => {
    setUserActive(true)
    const muteButton = document.getElementsByClassName('vjs-volume-panel')[0]
    muteButton && muteButton.classList.add('vjs-hover')
  }

  const handleMouseClick = () => {
    if(playerRef.current?.paused()){
      setUserActive(true)
    }
  }

  const qualityOption = () => {
    setOpenMenu(false)
    setOpenQuality(true)
  }

  const playbackOption = () => {
    setOpenMenu(false)
    setOpenRate(true)
  }


  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          const currentVolume = playerRef.current?.volume();
          const increasedVolume = Math.max(currentVolume + 0.1, 0); 
          playerRef.current?.volume(increasedVolume);
          break;
        case 'ArrowDown':
          const currentVolume2 = playerRef.current?.volume();
          const decreasedVolume = Math.max(currentVolume2 - 0.1, 0); 
          playerRef.current?.volume(decreasedVolume);
          break;
        case 'ArrowLeft':
          skipBackward()
          break;
        case 'ArrowRight':
          skipForward()
          break;
        case ' ':
          if(playerRef.current?.paused()){
            playerRef.current?.play()
          }else{
            playerRef.current?.pause()
            setUserActive(true)
          }
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };


  const changePlaybackRate = (rate) => {
    if (playerRef.current !== null) {
      playerRef.current?.playbackRate(rate);
      setOpenRate(false)
      setOpenMenu(true)
      if(rate==1){
        setPlaybackRate('Normal')
      }else{
        setPlaybackRate(rate+'x')
      }
    }
  };

  return (
    <>
        <div style={{width:"100vw", height:"100vh"}} data-vjs-player>
            <video ref={videoRef} className="video-js vjs-default-skin" />
            <div className='qualityDiv' ref={playBackRef}>
              {openQuality && 
              <div className='qualityMenu'>
                {data.url_list.map((e,index)=>{
                  return(
                    <>
                      <label key={index} onClick={currQuality} className='qualitySelect'><input type="radio" name='options' value={`${e.quality}`} checked={runQuality==e.quality && 'checked'} onChange={() => qualityOptionSwitch()}/><p className='qualityBlock'>{e.name}</p>{e.quality === 1080 && <span className='hdDiv'>HD</span>}</label>
                    </>
                  )
                })}
                <div onClick={()=>{setOpenQuality(false);setOpenMenu(true)}} className='mainMenu'><FontAwesomeIcon icon={faCaretLeft} style={{color: "#000000", paddingRight:"8px"}} /> Quality</div>
              </div>}
              {openRate &&
                <div className='rateMenu'>
                  <div onClick={()=>{setOpenRate(false);setOpenMenu(true)}} className='mainMenu'><FontAwesomeIcon icon={faCaretLeft} style={{color: "#000000", paddingRight:"8px"}} /> Speed</div>
                  <label onClick={() => changePlaybackRate(0.5)} className='rateSelect'><input type='radio' name='rates' value='0.5' checked={playbackRate==0.5+'x' && 'checked'} onChange={() => qualityOptionSwitch()}/><p style={{paddingLeft:"10px"}}>0.5x</p></label>
                  <label onClick={() => changePlaybackRate(0.75)} className='rateSelect'><input type='radio' name='rates' value='0.25' checked={playbackRate==0.75+'x' && 'checked'} onChange={() => qualityOptionSwitch()}/><p style={{paddingLeft:"10px"}}>0.75x</p></label>
                  <label onClick={() => changePlaybackRate(1)} className='rateSelect'><input type='radio' name='rates' value='1' checked={playbackRate=='Normal' && 'checked'} onChange={() => qualityOptionSwitch()}/><p style={{paddingLeft:"10px"}}>Normal</p></label>
                  <label onClick={() => changePlaybackRate(1.25)} className='rateSelect'><input type='radio' name='rates' value='1.25' checked={playbackRate==1.25+'x' && 'checked'} onChange={() => qualityOptionSwitch()}/><p style={{paddingLeft:"10px"}}>1.25x</p></label>
                  <label onClick={() => changePlaybackRate(1.5)} className='rateSelect'><input type='radio' name='rates' value='1.5' checked={playbackRate==1.5+'x' && 'checked'} onChange={() => qualityOptionSwitch()}/><p style={{paddingLeft:"10px"}}>1.5x</p></label>
                  <label onClick={() => changePlaybackRate(2)} className='rateSelect'><input type='radio' name='rates' value='2' checked={playbackRate==2+'x' && 'checked'} onChange={() => qualityOptionSwitch()}/><p style={{paddingLeft:"10px"}}>2x</p></label>
                </div>}
              {openMenu && <div className='extraMenu'>
                <button className='oneMenu' onClick={qualityOption}>
                  <span>Quality</span>
                  <span>{qualityShortName}<FontAwesomeIcon icon={faCaretRight} style={{color: "#000000", paddingLeft:"5px"}} /></span>
                </button>
                <button className='oneMenu' onClick={playbackOption}>
                  <span>Speed</span>
                  <span>{playbackRate}<FontAwesomeIcon icon={faCaretRight} style={{color: "#000000", paddingLeft:"5px"}} /></span>
                </button>
              </div>}
              <FontAwesomeIcon icon={faGear} onClick={showMenu} size="xl" style={{color: "#ffffff",cursor: "pointer"}} />
            </div>
            <div className='currentTime' ref={currRef}></div>
            {data.is_downloadable && <div onClick={videoDownload} style={{display:"flex", alignItems:"center", padding:"0 10px", cursor:"pointer"}} ref={downloadRef}><FontAwesomeIcon icon={faDownload} size="xl" style={{color: "#ffffff",}} /></div>}
            <div onClick={restartVideo} style={{display: !isMobile() ? "flex" : "none", alignItems:"center", padding:"0 10px", cursor:"pointer"}} ref={retryRef}><FontAwesomeIcon size='xl' style={{color: "#ffffff"}} icon={faRotateLeft} rotation={90} /></div>
            <div onClick={skipBackward} style={{display: !isMobile() ? "flex" : "none", alignItems:"center", padding:"0 10px", cursor:"pointer"}} ref={backwardRef}><FontAwesomeIcon icon={faBackward} size="xl" style={{color: "#ffffff",}} /></div>
            <div onClick={skipForward} style={{display: !isMobile() ? "flex" : "none", alignItems:"center", padding:"0 10px", cursor:"pointer"}} ref={forwardRef}><FontAwesomeIcon icon={faForward} size="xl" style={{color: "#ffffff",}} /></div>
            <div className='navbarVideo' style={{display: isMobile() && orientation=='landscape' ? 'none' : "flex", alignItems: "center", position:"absolute", width:"inherit", justifyContent: (isMobile() && orientation=='landscape') ? 'end' : "space-between", fontSize:"large", padding: orientation=='landscape' ? '1%' : "1% 5%", zIndex: "100"}}>
              <div style={{display: isMobile() && orientation=='landscape' && 'none', visibility:"hidden"}}><img
                      className="logoEg"
                      src={get_logo()}
                    /></div>
              <div style={{ visibility: userActive ? 'visible' : 'hidden', display: isMobile() && orientation=='landscape' && 'none'}}>{data.title}</div>
              {data.is_shareable ? <div style={{ visibility: (userActive) ? 'visible' : 'hidden' }} className='shareDiv' onClick={!isAdmin() && ShareCall}><FontAwesomeIcon  icon={faShareNodes} style={{color: "#ffffff"}} /></div> : <div style={{width:"40px"}}></div>}
            </div>
            <div style={{visibility : userActive ? 'visible' : "hidden", display: !isMobile() && 'none'}} className='overlayMobile'>
              <div onClick={skipBackward} style={{display: isMobile() ? "flex" : "none", alignItems:"center", margin:"0 5rem", cursor:"pointer", zIndex:"100"}}><FontAwesomeIcon icon={faBackward} size="2xl" style={{color: "#ffffff",}} /></div>
              <div onClick={manualPlay} style={{display: "flex", alignItems:"center", cursor:"pointer", zIndex:"100"}}><FontAwesomeIcon icon={isPaused ? faPlay : faPause} size="2xl" style={{color: "#ffffff",}} /></div>
              <div onClick={skipForward} style={{display: isMobile() ? "flex" : "none", alignItems:"center", margin:"0 5rem", cursor:"pointer", zIndex: "101"}}><FontAwesomeIcon icon={faForward} size="2xl" style={{color: "#ffffff",}} /></div>
            </div>
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPaused(false)
            playerRef.current?.play()
            setIsPopupOpen(false);
          }}
        >
          <div style={{ textAlign: "center", padding: "0 30px" }}>
            <div style={{ fontSize: "large", paddingTop: "0.2rem" }}>
              Share this quotation
            </div>     
            <div onClick={copyLinkUrl} className="cpyAlbum">
          <FontAwesomeIcon
              icon={faCopy}
              style={{ color: "#ffffff" }}
          />
            Copy Link
            </div>
            <div>
            <Link target='_blank' to={`${getWhatsappShareUrl(shareUrl)}`}>
              <button className="shareWp">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  style={{ color: "#ffffff" }}
                />
                Share
              </button>
            </Link>  
            </div>  
          </div>     
        </Popup>
        {linkCopied && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "11vh",
                    borderRadius: "0.5rem",
                    right: "1rem",
                    padding: "0.3rem 1.5rem",
                    zIndex: "1000",
                    backgroundColor: "rgb(91 91 91 / 70)",
                  }}
                >
                  <div
                    id="liveToast"
                    className="toast hide"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <div className="toast-body" style={{ color: "white" }}>
                      Link Copied
                    </div>
                  </div>
                </div>
        )}
    </>
  )
}

export default VideoPlayer