import React, {useEffect, useLayoutEffect, useRef, useState } from "react";
import "./GuestUpload.css";
import StoreToken from "../../components/StoreToken";
import { getAuthToken, isAdmin, globalLoader, get_platform_action_color } from "../../script/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faClock,
  faCircleCheck,
  faRotateRight,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import { useLocation, Link } from "react-router-dom";
import "react-gallery-carousel/dist/index.css";

const GuestUpload = ({data, bookingData}) => {
  const [selectedFile, setSelectedFile] = useState({});
  const [awsData, setAwsData] = useState();
  const [basicData, setBasicData] = useState();
  const [unsupportFile, setUnSupportFile] = useState(false);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false)
  const [uploadInfo, setUploadInfo] = useState(false);
  const [popup, setPopup] = useState(false);
  const { storeToLocal } = StoreToken();
  const uploadRef = useRef();
  const index = useRef(0);
  const toBeUploaded = useRef([]);
  const ignoreDuplicate = useRef([]);
  const duplicacy = useRef([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workId = queryParams.get("work_id");
  useEffect(() => {
    setAwsData(JSON.parse(localStorage.getItem("awsData")));
  }, []);

  const validData = (event) => {
    const supported = event.target.files ? [...event.target.files] : [];
    const updated = supported.filter((e) => e.type.split("/")[0] === "image");

    if (updated.length !== supported.length) {
      setUnSupportFile(true);
      setTimeout(() => {
        setUnSupportFile(false);
      }, 3000);
    }
    toBeUploaded.current = updated;
    const files = updated;
    Array.from(files).map((e) => {
      if (Object.keys(duplicacy.current).length === 0) {
        setSelectedFile((prevState) => ({
          ...prevState,
          [e.name]: {
            file: e,
            flag: false,
          },
        }));
      } else if (
        Object.keys(duplicacy.current).length > 0 &&
        duplicacy.current.hasOwnProperty(e.name)
      ) {
        setSelectedFile((prevState) => ({
          ...prevState,
          [e.name]: {
            file: e,
            flag: true,
          },
        }));
      } else if (
        Object.keys(duplicacy.current).length > 0 &&
        !duplicacy.current.hasOwnProperty(e.name)
      ) {
        setSelectedFile((prevState) => ({
          ...prevState,
          [e.name]: {
            file: e,
            flag: false,
          },
        }));
      }
    });
    toBeUploaded.current.length > 0 && setPopup(true);
  };

  useLayoutEffect(() => {
    storeToLocal();
  }, []);

  const createThumb = () => {
    setLoading(true)
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    const data = {}
    axios
      .post(`${process.env.REACT_APP_STAG_URL}/api/work/guest/upload/${workId}/`, data, {
        headers,
      })
      .then(()=>{
        bookingData(false)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
        window.location.reload()
      });
  }

  const getUrl = (event) => {
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_STAG_URL}/api/work/guest/upload/${workId}/`, {
        headers,
      })
      .then((response) => {
        localStorage.setItem("awsData", JSON.stringify(response.data.data));
        setAwsData(JSON.parse(localStorage.getItem("awsData")));
        validData(event);
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });
  };

  const basicDetails = () => {
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_STAG_URL}/api/booking/${workId}/meta/`, {
        headers,
      })
      .then((response) => {
        setBasicData(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });
  };

  useEffect(() => {
    if(workId){
      basicDetails();
    }
  }, []);

  useEffect(() => {
      const handleBeforeUnload = (event) => {
          if (current !== 0) {
              event.preventDefault();
              const confirmMessage = "Are you sure you want to navigate away? Your selection will be lost.";
              const confirmed = window.confirm(confirmMessage);
              if (!confirmed) {
              event.preventDefault();
              }
          }
          
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
  
      };
  }, [current]);

  const handleFileChange = async (event) => {
    if (!awsData) {
      getUrl(event);
    } else if (awsData && awsData.expires_at <= Date.now()) {
      getUrl(event);
    } else {
      validData(event);
    }
  };

  if (Object.keys(selectedFile).length > 0)
    duplicacy.current = { ...selectedFile };
  popup
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "");
  const compressionUpload = async (imageFile) => {
    const regex = /[^a-zA-Z0-9_.\-:]/g;
    const lastModified_meta = new Date(imageFile.lastModified).toISOString();
    const date = new Date(imageFile.lastModified);
    const lastModified = date.getTime();
    const newFileName = `${lastModified}.${imageFile.name.replace(regex, "")}`;
    const uniqueName = new File([imageFile], newFileName, {
      type: "image/jpeg",
    });
    const appendName = uniqueName;
    if (
      ignoreDuplicate.current.includes(appendName.name) &&
      duplicacy.current[imageFile.name].flag
    ) {
      const ele = document.getElementsByClassName(`${imageFile.name}`);
      ele[0].style.display = "none";
      ele[1].style.display = "none";
      ele[2].style.display = "block";
      ele[3].style.display = "none";
      ele[4].style.display = "none";
      setCurrent((prevState) => prevState + 1);
      const updateArray = { ...selectedFile };
      delete updateArray[imageFile.name];
      setSelectedFile(updateArray);
      index.current = index.current + 1;
      setCurrent((prevState) => prevState - 1);
    } else {
      ignoreDuplicate.current.push(appendName.name);
      const elements = document.getElementsByClassName(`${imageFile.name}`);
      elements[0].style.display = "none";
      elements[1].style.display = "block";
      elements[2].style.display = "none";
      elements[3].style.display = "none";
      elements[4].style.display = "none";
      if (navigator.onLine) {
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
        };
        var convert = appendName;
        setCurrent((prevState) => prevState + 1);
        index.current = index.current + 1;
        try {
          if (
            imageFile.type === "image/heif" ||
            imageFile.type === "image/heic"
          ) {
            const result = await heic2any({ blob: appendName });
            const convertedFile = new File(
              [result],
              appendName.name.toLowerCase().replace(/\.heic$/, ".jpg"),
              {
                type: "image/jpeg",
              }
            );
            const converted = new File(
              [convertedFile],
              appendName.name.toLowerCase().replace(/\.heif$/, ".jpg"),
              {
                type: "image/jpeg",
              }
            );
            convert = converted;
          }
          const preCompressedFile = await imageCompression(convert, options);
          const compressedFile = new File(
            [preCompressedFile],
            preCompressedFile.name.replace(/\.heic$/, ".jpg"),
            {
              type: "image/jpeg",
            }
          );
          try {
            if (imageFile === null) {
              return console.error("Please Upload image");
            }
            const data = new FormData();
            data.append(
              "Content-Disposition",
              awsData.fields["Content-Disposition"]
            );
            data.append(
              "x-amz-storage-class",
              awsData.fields["x-amz-storage-class"]
            );
            data.append("key", awsData.fields["key"]);
            data.append("AWSAccessKeyId", awsData.fields["AWSAccessKeyId"]);
            data.append("policy", awsData.fields["policy"]);
            data.append("signature", awsData.fields["signature"]);
            data.append("Content-Type", awsData.fields["Content-Type"]);
            data.append("x-amz-meta-created-timestamp", `${lastModified_meta}`);
            data.append("file", compressedFile);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: awsData.url,
              headers: {
                "Content-Type": "image/jpeg",
              },
              data: data,
            };

            axios
              .request(config)
              .then(() => {
                setCurrent((prevState) => prevState - 1);
                const elements2 = document.getElementsByClassName(
                  `${imageFile.name}`
                );
                elements2[0].style.display = "none";
                elements2[1].style.display = "none";
                elements2[2].style.display = "block";
                elements2[3].style.display = "none";
                elements2[4].style.display = "none";
                const updateflag = { ...duplicacy.current };
                updateflag[imageFile.name].flag = true;
                duplicacy.current = updateflag;
              })
              .catch((error) => {
                console.log(error);
                setCurrent((prevState) => prevState - 1);
                const elements3 = document.getElementsByClassName(
                  `${imageFile.name}`
                );
                elements3[0].style.display = "none";
                elements3[1].style.display = "none";
                elements3[2].style.display = "none";
                elements3[3].style.display = "block";
                elements3[4].style.display = "block";
              });

          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      } else {
        elements[0].style.display = "none";
        elements[1].style.display = "none";
        elements[2].style.display = "none";
        elements[3].style.display = "block";
        elements[4].style.display = "block";
      }
    }
  };

  useEffect(() => {
    if (
      current < 5 &&
      Object.keys(selectedFile).length > 0 &&
      index.current < toBeUploaded.current.length
    ) {
      compressionUpload(toBeUploaded.current[index.current]);
    }
  }, [selectedFile, current]);

  const retry = (event) => {
    for (let i = 0; i < Object.keys(selectedFile).length; i++) {
      if (toBeUploaded.current[i].name === event.currentTarget.className) {
        compressionUpload(toBeUploaded.current[i]);
      }
    }
  };


  return (
    <>  
      {(
        <>
          {basicData && (basicData?.guest_gallery_meta?.is_active ? (
            <>
              <div
                style={{
                  position: "fixed",
                  zIndex: "100",
                  top: "0",
                  left: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  height: "100vh",
                  width: "100vw",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  display: popup ? "flex" : "none",
                }}
              >
                <div
                  className={window.innerWidth<600 ? `uploadPopup drawer open` : "uploadPopup"}
                >
                  <div
                    style={{
                      textAlign: "center",
                      padding: "15px 0",
                      fontSize: "1.2rem",
                      fontWeight: "800",
                      position: "relative",
                      width:window.innerWidth<600 ? "95%" : "100%",
                      overflow:"hidden"
                    }}
                  >
                    {current !== 0 ? "Uploading..." : "Uploaded"}
                    <span
                      style={{ position: "absolute", right: "0", cursor:"pointer" }}
                    >
                      {current !== 0 ? <div></div> : <button onClick={() => {
                              setPopup(false);
                              createThumb();
                              // setUploadInfo(true);
                              setSelectedFile([]);
                              index.current = 0;
                            }} style={{background: get_platform_action_color(), color:"white", borderRadius:"5px", fontSize:"smaller", padding:"3px 6px"}}>Done</button>}
                    </span>
                  </div>
                  <hr></hr>
                  <div className="popupContent">
                    {toBeUploaded.current.length > 0 &&
                      toBeUploaded.current.map((e, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              padding: "10px 20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                            key={index}
                          >
                            <span style={{ padding: "0 10px" }}> {e.name}</span>
                            <span className={e.name}>
                              <FontAwesomeIcon
                                icon={faClock}
                                style={{ color: "#000000" }}
                              />
                            </span>
                            <span style={{ display: "none" }} className={e.name}>
                              {globalLoader('xs')}{" "}
                            </span>
                            <span className={e.name} style={{ display: "none" }}>
                              {" "}
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                style={{ color: "#0d7575" }}
                              />
                            </span>
                            <span className={e.name} style={{ display: "none" }}>
                              {" "}
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                style={{ color: "#ff0000" }}
                              />
                            </span>
                            <span
                              className={e.name}
                              onClick={retry}
                              style={{ display: "none", paddingLeft: "10px" }}
                            >
                              {" "}
                              <FontAwesomeIcon icon={faRotateRight} />
                            </span>
                          </div>
                          <hr></hr>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
              {Object.keys(data).length>0 && data[workId]?.is_upload_allowed && <div className="uploadDiv">
                <div>
                  <input
                    style={{ display: "none" }}
                    ref={uploadRef}
                    accept=".heic, .heif, .jpeg, .jpg"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                  {basicData.guest_gallery_meta.guest_upload_allowed && (
                    <button
                      className="uploadButton"
                      style={{backgroundColor:get_platform_action_color(), display:"none"}}
                      onClick={() => {
                        !isAdmin() && uploadRef.current.click();
                      }}
                    >
                        <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        size={window.innerWidth>1200 ? 'sm' : 'xl'}
                        style={{ color:window.innerWidth>1200 ? "#ffffff" : "#000000" }}
                      />
                      {" "}<span>Upload</span>
                    </button>
                  )}
                </div>
              </div>}
              {uploadInfo && <div className="infoDiv">
                <div style={{background:"white", padding:"20px", borderRadius:"10px"}}>
                  <div>Uploaded photos will be visible in few minutes.</div>
                  <div style={{textAlign:"right", paddingTop:"10px"}}><button style={{padding:"2px 8px", background: get_platform_action_color(), color:"white", borderRadius:"5px"}} onClick={()=>setUploadInfo(false)}>Okay</button></div>
                </div>
              </div>}
            </>
          ) : (
            <>
              <div>{basicData?.message}</div>
              <Link to="/">
                <button
                  style={{
                    border: "1px solid black",
                    background: "black",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    fontWeight: "800",
                  }}
                >
                  Go to Dashboard
                </button>
              </Link>
            </>
          ))}
          {loading && <div className="overlayLoader">{globalLoader()}</div>}
        </>
      )
      }
      {unsupportFile && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.7)",
            color: "white",
            zIndex: "100",
            padding: "5px 10px",
            borderRadius: "10px",
            top: "28px",
            right: "20px",
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} style={{ color: "white" }} />{" "}
          Only images are supported!
        </div>
      )}
    </>
  );
};

export default GuestUpload;
