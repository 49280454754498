

export const S_URL = process.env.REACT_APP_STAG_URL

export const FADE_INTERVAL = 300;
export const ANIMATION_INTERVAL = 300;
export const DELAY_INTERVAL = 3000;
export const AFTER_POPUP_INTERVAL = 2100;
export const G_CLIENT_ID = "258206932309-68hmfr8t1rhiofd253blnrogu41o32vi.apps.googleusercontent.com";
export const FB_CLIENT_ID = "1692389344107335";
export const FB_APP_VERSION = "v2.7";
export const IS_GODMODE = false;
export const RED_EYE = "https://assets.graphia.in/site_media/webapp/red_eye.png";
export const BLACK_EYE = "https://assets.graphia.in/site_media/webapp/black_eye.png";
export const WHITE_EYE = "https://assets.graphia.in/site_media/webapp/white_eye.png";
export const MAX_DOWNLOAD_SIZE_IN_MOBILE = 262144000;
export const DASHBOARD = 'dashboard'
export const WORK_DETAILS = 'work_details'
export const GUEST_UPLOADS = 'guest_uploads'
export const ALBUM_SELECTION = 'album_selection'
export const SHARED_LINKS = 'shared_links'
export const HOME_URL = 'home_url'
export const USER_FOLDERS = 'user_Folders'
