import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon, Badge, Divider, Spinner, useDisclosure, Card, Image, ModalOverlay, CardHeader, CardFooter, CardBody, Text
} from '@chakra-ui/react'
import { MdOutlinePayments, MdPhoneCallback, MdOutlineAddPhotoAlternate, MdOutlinePhotoSizeSelectLarge, MdOutlinePayment, MdOutlineLibraryBooks } from "react-icons/md";
import { useStateContext } from '../../context/StateContext';
import { getQueryVariable, GetProfile, getLoginPage, getAuthToken, getAPIUrl, token_url, isAdmin, quotation_string, getWhatsappShareUrl, globalLoader, Platform, downloadReceipt, catchError, get_platform_bg_color, handleLibrary, setNavigationHistory } from '../../script/util.js';
import './Dashboard.css'
import axios from 'axios';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { FaPhotoVideo } from "react-icons/fa";
import { BiUserCheck } from "react-icons/bi";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineShare } from "react-icons/hi";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    TableCaption,
    Tfoot
} from '@chakra-ui/react'
import { TfiReload } from "react-icons/tfi";
import { BiUnlink } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from "react";
import CopyButton from '../../components/CopyButton';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import * as CONST from "../../script/const.js";
import ShareButton from 'react-share/lib/ShareButton';
import Popup from '../../components/Popup/Popup.js';
import DownloadIcon from "../../images/download.png";
import libraryIcon from "../../images/libraryIcon.png"
import bookingIcon from "../../images/bookings.png";

const Dashboard = () => {
    document.title = "Dashboard | EventGraphia";
    if (Platform.isWG) {
        document.title = "Dashboard | WedGraphia"
    }
    const navigate = useNavigate();
    const [loading, setloading] = useState(true)
    const { isLoggedin, setisLoggedin } = useStateContext();
    const divRef = useRef(null)
    const {  onOpen, onClose } = useDisclosure();
    const [paydetail, setpaydetail] = useState("loading..")
    const [openShare, setopenShare] = useState(false)
    const [openEventDetail, setopenEventDetail] = useState(false)
    const [bookings, setbookings] = useState([])
    const [bookingDetail, setbookingDetail] = useState([{}])
    const [eventDetailPopup, seteventDetailPopup] = useState(null)
    const [paymentHistoryPopup, setpaymentHistoryPopup] = useState(null)
    const [sharePopup, setsharePopup] = useState(null)
    const [openpaymentHistory, setopenpaymentHistory] = useState(false)
    const [openedTabIndex, setOpenedTabIndex] = useState(-1);
    const cancelRef = React.useRef()
    const [onOpenAlert, setonOpenAlert] = useState(false)
    const [isRegConfirm, setisRegConfirm] = useState(false)
    const [shareDetailsArray, setShareDetailsArray] = useState({});
    const [sharePayloads, setSharePayloads] = useState({ bid: "", pk: "" });
    const [book_Pk, setBook_Pk] = useState()
    const [book_Id, setBook_Id] = useState()
    const [currId, setCurrId] = useState()
    const [dashWidth, setDashWidth] = useState()
    const [currentDimensions, setCurrentDimensions] = useState(window?.innerWidth)

    useLayoutEffect(() => {
        if (getQueryVariable('token')) {
            localStorage.clear();
            localStorage.setItem('eventGraphiaToken', getQueryVariable('token'));
            window.location = '/'
        }

        if (localStorage.eventGraphiaToken === null) {
            getLoginPage();
        }
        if (localStorage.eventGraphiaToken !== null) {
            if (localStorage.getItem('auth_dict') === null) {
                GetProfile(function () {
                    setisLoggedin(true);
                    axios({
                        method: 'get',
                        url: getAPIUrl('/api/booking/all/'),
                        headers: {
                            Authorization: getAuthToken(),
                        },
                        dataType: "json",
                        cache: false,

                    }).then(function (res) {
                        const tp = res.data;
                        if (tp.success) {
                            setloading(false)
                            setbookings([...tp.current_bookings, ...tp.past_bookings]);
                        }
                    }).catch((error) => {
                        catchError(error)
                    });
                });
            }
            else {
                axios({
                    method: 'get',
                    url: getAPIUrl('/api/booking/all/'),
                    headers: {
                        Authorization: getAuthToken(),
                    },
                    dataType: "json",
                    cache: false,

                }).then(function (res) {
                    const tp = res.data;
                    if (tp.success) {
                        setloading(false)
                        setisLoggedin(true);
                        setbookings([...tp.current_bookings, ...tp.past_bookings]);
                    }
                }).catch((error) => {
                    catchError(error)
                });
            }
        }
        else {
            setisLoggedin(false);
            getLoginPage();
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setDashWidth(window.innerWidth);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getShareDetail = async (booking_pk, bid) => {
        setloading(true);
        setShareDetailsArray({})
        setBook_Pk(booking_pk)
        setBook_Id(bid)
        if (shareDetailsArray.hasOwnProperty(booking_pk)) {
            showShareDetails(booking_pk, bid);
            return;
        }
        if (booking_pk !== undefined) {
            try {
                const res = await axios.get(getAPIUrl(`/api/share/url/${booking_pk}/`), {
                    headers: {
                        Authorization: getAuthToken(),
                    },
                });

                const sharedCall = res.data;
                if (sharedCall.success) {
                    const updatedShareDetailsArray = { ...shareDetailsArray };
                    const x = sharedCall.shared_data;
                    updatedShareDetailsArray[booking_pk] = {
                        booking_id: bid,
                        shared_array: x,
                    }
                    setShareDetailsArray(updatedShareDetailsArray);
                    setSharePayloads({ bid: bid, pk: booking_pk })
                    { !sharePayloads.bid && <>{globalLoader()}</> }
                    sharePayloads.bid && showShareDetails(booking_pk, bid)
                }
            } catch (error) {
                catchError(error);
            }
        }
    };

    const handleOrientationChange = () => {
        setCurrentDimensions(window.innerWidth)
    };

    useEffect(() => {
        window.addEventListener('resize', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    useEffect(() => {
        getShareDetail(book_Pk, book_Id)
    }, [sharePayloads, book_Pk, book_Id])

    const handlePay = bid => {
        setpaydetail("");
        onOpen()
        var data = {
            booking_id: bid
        }
        axios({
            method: 'post',
            url: getAPIUrl('/api/payment/create/web/'),
            headers: {
                Authorization: getAuthToken(),
            },
            data: data,
            dataType: "json",
            cache: false,
        }).then(function (res) {
            setpaydetail(res.data);
        }).catch((error) => {
            catchError(error)
        })
    }

    const showEventDetails = (id) => {
        seteventDetailPopup(null);
        setopenEventDetail((cur) => !cur);
        const body = document.body
        body.classList.add("condBody")
        seteventDetailPopup(<div onClick={() => { setopenEventDetail(false); body.classList.remove("condBody") }} style={{ top: "0", left: "0", position: "fixed", zIndex: "20" }}>
            <div style={{ position: "fixed", height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0.6)", padding: "3%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                {bookingDetail?.filter(obj => obj.booking_id?.includes(id)).map((obj, index) => {
                    return <>
                        <div onClick={(event) => { event.stopPropagation() }} className="booking-detail-panel" key={obj.booking_id}>
                            <button className='closeList' onClick={() => { setopenEventDetail(false); body.classList.remove("condBody") }}>&times;</button>
                            <div className="booking-detail-header">
                                <h3>Final Cost:  {obj?.currency_symbol}{obj?.actual_cost}</h3>
                            </div>
                            <div className="details-section">

                                {obj?.event_detail.map((val, index) => {
                                    return <>
                                        <fieldset key={index}>
                                            <legend>
                                                {val.subcategory}
                                            </legend>
                                            <Table >
                                                <Tbody>
                                                    <Tr>
                                                        <Th fontSize='lg'>Location</Th>
                                                        <Td fontSize='md'>{val.city}</Td>
                                                    </Tr>

                                                    <Tr>
                                                        <Th fontSize='lg'>Pricing basis</Th>
                                                        <Td fontSize='md'>{(val.is_per_day) ? "Daily" : ""}{(val.is_per_hour) ? "Hourly" : ""}</Td>
                                                    </Tr>

                                                    <Tr>
                                                        <Th fontSize='lg'>Duration</Th>
                                                        <Td fontSize='md'>Full Day</Td>
                                                    </Tr>

                                                    <Tr>
                                                        <Th fontSize='lg'>Services</Th>
                                                        <Td fontSize='md'>
                                                            {val.no_of_photographers > 0 ?
                                                                <>
                                                                    Photographers: {val.no_of_photographers} ({val.photographer_quality})
                                                                    {val.no_of_cinematographers > 0 ?
                                                                        <>
                                                                            <br /><br /></> : ""}
                                                                </>
                                                                : ""}
                                                            {val.no_of_cinematographers > 0 ?
                                                                <>
                                                                    Cinematographers:{val.no_of_cinematographers} ({val.cinematographer_quality})
                                                                </>
                                                                : ""}


                                                        </Td>
                                                    </Tr>
                                                    <Tr cn>
                                                        <Th fontSize='lg'>Details</Th>
                                                        <Td fontSize='md' className='venue-detail'>


                                                            {val.date_data.map((val, index) => {
                                                                return <>
                                                                    <li>
                                                                        <b>Date & time:</b> {val.date} At {val.time}
                                                                        {val.venue === "" ? "" : <br />}

                                                                        {val.venue === "" ? "" :
                                                                            <>
                                                                                <b>Venue:</b> {val.venue}
                                                                                {val.notes === "" ? "" : <br />}
                                                                            </>
                                                                        }
                                                                        {val.notes === "" ? "" :
                                                                            <>
                                                                                <b>Notes:</b> {val.notes}
                                                                            </>
                                                                        }
                                                                    </li>
                                                                </>
                                                            })}
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </fieldset></>
                                })}

                            </div>
                        </div>
                    </>
                }
                )}
            </div>
        </div>)
    }

    const showPaymentHistory = (id) => {
        setpaymentHistoryPopup(null);
        setopenpaymentHistory((cur) => !cur);
        const body = document.body;
        body.classList.add("condBody");
    
        setpaymentHistoryPopup(
            <Popup
                isOpen={true}
                onClose={() => {
                    setopenpaymentHistory(false);
                    body.classList.remove("condBody");
                }}
            >
                    <table className="payment-history-table">
                        <thead>
                            <tr className='payment-history-table-head'>
                                <th>Status</th>
                                <th>Cost</th>
                                <th>Transaction ID</th>
                                <th>Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookingDetail?.filter(obj => obj.booking_id?.includes(id)).map(obj =>
                                obj.payment_detail?.map((x, index) => (
                                    <tr key={index} className="payRes">
                                        <td>
                                            <span
                                                style={{
                                                    padding: window.innerWidth > 600 ? "5px 10px" : "3px",
                                                    borderRadius: "5px",
                                                    backgroundColor: x.status === "Success" ? "green" : "gray",
                                                    color: "white",
                                                    fontSize: "1em",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {x.status}
                                            </span>
                                        </td>
                                        <td style={{fontSize:'1em'}}>{x.currency_symbol + x.amount}</td>
                                        <td  className='payment-table-transaction-cell'>
                                            {x.transaction_id}
                                        </td>
                                        <td>
                                            <button
                                                style={{
                                                    padding: window.innerWidth > 600 ? "8px 16px" : "4px",
                                                    border: "none",
                                                    borderRadius: "4px",
                                                    cursor: x.installment_id ? "pointer" : "not-allowed",
                                                    fontWeight: window.innerWidth < 600 && "bold"
                                                }}
                                                onClick={() => x.installment_id && downloadReceipt(x.installment_id, x.filename)}
                                            >
                                                <img src={DownloadIcon} alt="Download" style={{height: "1rem", width: "1rem"}} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
            </Popup>
        );
    };
    
    const showShareDetails = () => {
        const bid = sharePayloads.bid
        const pk = sharePayloads.pk
        setopenShare(true);
        const shareData = shareDetailsArray[pk];
        const body = document.body
        sharePopup && body.classList.add("condBody")
        setloading(false)
        shareData && setsharePopup(<div onClick={() => { setopenShare(false); body.classList.remove("condBody") }} style={{ top: "0", left: "0", position: "fixed", zIndex: "20" }}>
            <div style={{ position: "fixed", height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0.6)", padding: "3%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {loading ? <>
                    {globalLoader()}
                </> : <>
                    <div onClick={(event) => { event.stopPropagation() }} className="shared-list">
                        <button className='closeList' onClick={() => { setopenShare(false); body.classList.remove("condBody") }}>&times;</button>
                        {shareData?.shared_array?.map((shareDetail) => {
                            return <>
                                <li key={shareDetail.share_url}>
                                    <div className="shared-link">
                                        <a style={{ color: shareDetail.is_disabled && "rgb(153, 153, 153", textDecoration: shareDetail.is_disabled && "line-through", cursor: shareDetail.is_disabled && "not-allowed", pointerEvents: shareDetail.is_disabled && "none" }} href={shareDetail.is_disabled ? '' : shareDetail.share_url} className="shared-link-text" target='_blank'>https://..{shareDetail.share_url?.substring(shareDetail.share_url.length - 11, shareDetail.share_url.length)}</a>
                                        <CopyButton value={shareDetail} />
                                    </div>

                                    <div className="shared-btns">
                                        {bookingDetail?.filter(obj => obj.booking_id?.includes(bid)).map(obj => (
                                            <button style={{ backgroundColor: shareDetail.is_disabled && "rgb(153, 153, 153" }} disabled={shareDetail.is_disabled} className="regen" onClick={() => { !isAdmin() && regenerate(shareDetail.share_passcode, pk, bid) }}><TfiReload /><span className='regenText'>Regenerate</span></button>
                                        ))}


                                        <button style={{ backgroundColor: shareDetail.is_disabled && "black" }} className={shareDetail.is_disabled ? "fa-solid fa-check" : "disable"} onClick={() => { !isAdmin() && disableShareURL(shareDetail.share_passcode, shareDetail.is_disabled, pk, bid) }}><BiUnlink /><span className='disText'>{shareDetail.is_disabled ? "Enable" : "Disabled"}</span></button>
                                        <Link to={`${getWhatsappShareUrl(shareDetail.share_msg)}`}><button style={{ backgroundColor: shareDetail.is_disabled && "rgb(153, 153, 153" }} disabled={shareDetail.is_disabled} className="share" ><FaWhatsapp /><span className='shareText'>Share</span></button></Link>
                                        <ShareButton className="share-everywhere" url={shareDetail.share_url || shareDetail.share_url} title={"Share"} />

                                    </div>
                                    <div
                                        style={{
                                            display: 'inline-flex',
                                            color: '#6c757d',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            fontStyle: 'italic',
                                            margin: '5px',
                                            whiteSpace: 'nowrap',
                                            width: '70px'
                                        }}
                                    >
                                        <span>{shareDetail.share_tag}</span>
                                    </div>
                                </li>
                            </>
                        })}
                    </div></>}
            </div>
        </div>)
        setloading(false);
    }

    useEffect(() => {
        const getBookingDetails = async (bide) => {
            const index = bookingDetail.findIndex((item) => item.booking_id === bide);
            if (index !== -1) {
                return;
            }

            try {
                const response = await axios.post(
                    getAPIUrl('/api/booking/get_detail/v2/'),
                    { booking_id: bide },
                    {
                        headers: { Authorization: getAuthToken() },
                    }
                );

                const data = response.data.booking_detail;
                data.booking_id = bide;

                setbookingDetail((prev) => [...prev, data]);

            } catch (error) {
                catchError(error)
            }
        };
        const promises = bookings?.map((dd) => getBookingDetails(dd.booking_id));
        if (promises?.length) {
            Promise.allSettled(promises).then(() => {
                setbookingDetail((prev) =>
                    prev.filter(
                        (item, index, arr) =>
                            arr.findIndex((t) => t.booking_id === item.booking_id) === index
                    )
                );
            });
        }

    }, [bookings]);

    useEffect(() => {
        const bid = getQueryVariable('id');
        let idx;
        if (bid === null) {
            idx = 0;
        } else {
            idx = bookings.findIndex(obj => obj.booking_pk == bid);
            if (idx === -1 && bookings.length > 0) {
                // Navigate to the first booking's id
                navigate(`/?id=${bookings[0].booking_pk}`);
            }

            if (idx === -1) {
                idx = 0;
            }
        }
        setOpenedTabIndex(idx);
    }, [bookings]);

    useEffect(()=>{
        setNavigationHistory(location, CONST.DASHBOARD)
    },[])

    const regenerate = async (pass, bookingPk, bid) => {
        if (!window.confirm("The existing link will be deactivated and a new link will be generated.\nAre you sure that you want to generate a new link?")) {
            return;
        }
        try {
            const sharedData = {
                action_flag: 'regenerate',
                share_passcode: pass,
            };

            const response = await fetch(getAPIUrl(`/api/share/url/${bookingPk}/`), {
                method: 'PUT',
                headers: {
                    Authorization: getAuthToken(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sharedData),
            });

            const res = await response.json();


            if (res.success) {
                setloading(false)

                for (let index = 0; index < shareDetailsArray[bookingPk].shared_array.length; index++) {
                    if (shareDetailsArray[bookingPk].shared_array[index].share_passcode === pass) {
                        shareDetailsArray[bookingPk].shared_array[index].share_passcode = res.share_passcode
                        shareDetailsArray[bookingPk].shared_array[index].share_url = res.share_url
                        shareDetailsArray[bookingPk].shared_array[index].share_msg = res.share_msg
                        break
                    }
                }
                getShareDetail(bookingPk, bid)
            } else {
                setloading(false)
            }
        } catch (error) {
            setloading(false)
            console.error('API request failed:', error);

        }
    };

    const disableShareURL = (pass, is_disabled, bookingPk, bid) => {
        var passcode = pass;
        var shared_data = {
            action_flag: 'deactivate',
            share_passcode: passcode,
            is_disabled: !is_disabled,
        };

        const config = {
            method: "PUT",
            url: getAPIUrl(`/api/share/url/${bookingPk}/`),
            headers: {
                Authorization: getAuthToken(),
            },
            data: shared_data,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        };
        axios(config)
            .then((response) => {
                for (let index = 0; index < shareDetailsArray[bookingPk].shared_array.length; index++) {
                    if (shareDetailsArray[bookingPk].shared_array[index].share_passcode === pass) {
                        shareDetailsArray[bookingPk].shared_array[index].is_disabled = response.data.is_disabled
                        break
                    }
                }
                getShareDetail(bookingPk, bid)
            })
            .catch((error) => {
                catchError(error)
            });
    };
    const alert = <AlertDialog
        onClose={() => { setonOpenAlert(false) }}
    >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Delete Customer
                </AlertDialogHeader>

                <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                    <button ref={cancelRef} onClick={() => { setonOpenAlert(false) }}>
                        Cancel
                    </button>
                    <button colorScheme='red' onClick={() => { setisRegConfirm(true) }} ml={3}>
                        Confirm
                    </button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id')
    useEffect(() => {
        bookings.length > 0 && !id && navigate(`/?id=${bookings[0].booking_pk}`)
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [bookings])

    useEffect(() => {

    }, [id])

    return (
        <>
            {isLoggedin ?
                <div className="dashboard">
                    <Navbar title={"Dashboard"} location={token_url(`${process.env.REACT_APP_STAG_URL}`)} />
                    <div>
                        <div style={{backgroundColor: currentDimensions > 1200 && get_platform_bg_color(), top: currentDimensions>1200 && '0'}} className= {`${(currentDimensions > 1200 ? 'topSwitchTabs' : 'bottomSwitchTabs')}`}>
                            {<p className="tabsPara" style={{borderBottom: (currentDimensions > 1200 ? '3px solid white' : '3px solid black')}} >
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={bookingIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px"}} />
                                <span>Bookings</span>
                            </p>}
                            {<p className="tabsPara" onClick={handleLibrary}>
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={libraryIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px", width:"auto"}} />
                                <span>Library</span>
                            </p>}
                        </div>
                    </div>
                    {loading ?
                        <div className='fullpage-overlay'>
                            {globalLoader()}
                        </div> :

                        <Accordion allowToggle className='accordion' ref={divRef} index={openedTabIndex} >
                            {(bookings?.length === 0) ?

                                <>
                                    <div className="no-bookings">
                                        <div className="no-booking-header">
                                            <h2>No Bookings Found</h2>
                                        </div>
                                        <div className="callback-section">
                                            <h3>Want to get your first event booked?</h3>
                                            <button className='filled-btn' onClick={() => { window.location = token_url(`${process.env.REACT_APP_STAG_URL}/callback`) }}><MdPhoneCallback />Get a Callback</button>
                                        </div>
                                        <div className="eventgraphia-data">
                                            <div className="box-data">

                                                <span><BiUserCheck /></span>
                                                <p>Shot 1,000+ wedding related events</p>
                                            </div>
                                            <div className="box-data">

                                                <span><MdOutlineAddPhotoAlternate /></span>
                                                <p>Shot 4+ million photos</p>
                                            </div>
                                            <div className="box-data">

                                                <span><AiOutlineVideoCameraAdd /></span>
                                                <p>Shot 300,000 minutes of videos</p>
                                            </div>
                                            <div className="box-data">

                                                <span><FaPhotoVideo /></span>
                                                <p>Generated 125+ TB of pictures & videos</p>
                                            </div>
                                        </div>
                                    </div>

                                </> :

                                <>
                                    {bookings?.map((dd, index) => {
                                        return <>
                                            <React.Fragment key={dd.booking_id}>
                                                <AccordionItem isDisabled={!(dd?.work_list?.length > 0) && bookingDetail?.find(obj => obj.booking_id?.includes(dd.booking_id) && (obj.payment_detail.length === 0 && obj?.estimations?.length === 0))}>
                                                    <h2 >
                                                        <AccordionButton _hover={{ background: "transparent" }}>
                                                            <Card id={dd.booking_pk} style={{ width: "100%", display: "flex", textAlign: "center", padding: "20px" }} as="span" onClick={() => { setOpenedTabIndex(index); setCurrId(dd.booking_id); navigate('/?id=' + dd.booking_pk); }} flex='1' textAlign='left' className="accordion-heading">
                                                                <Badge mr='2' className='booking-status-badge' fontSize='0.8em' colorScheme='green'>
                                                                    {dd.booking_status}
                                                                </Badge>
                                                                <p className='booking-id'>{dd.booking_name}</p>
                                                                <AccordionIcon className='dropAlbum' />
                                                            </Card>
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={4}>

                                                        <Divider orientation='horizontal' />

                                                        <div className={(dd?.work_list?.length === 1) ? "single-deliverable-main" : "main-container-del"}>
                                                            {dd?.work_list?.length > 0 ? <div className={(dd?.work_list?.length === 1) ? "single-deliverable" : "deliverables"}>
                                                                {dd.work_list?.map((wl, index) => {

                                                                    return <>
                                                                        <Link to={token_url(wl.url)} key={wl.url}>
                                                                            <Card maxW='md' className='del-cards' position={'relative'}>
                                                                                <Image
                                                                                    objectFit='cover'
                                                                                    src={wl.cover_url}
                                                                                    className='del-card-img'
                                                                                />
                                                                                <div className="card-overlay-deliverable"></div>
                                                                                <h3 className="card-text-overlay">{wl.name}</h3>
                                                                            </Card>
                                                                        </Link>
                                                                    </>
                                                                })}
                                                            </div> : ""}

                                                            {bookingDetail.length > 1 ? <div className={(dd?.work_list?.length === 1) ? "single-deliverable-tab" : "booking-detail-tab"}>
                                                                {/* {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj?.my_work_detail_web[0]?.actions[0]?.action_type === "VIEW_DRAFTS")).map(obj => (
                                                                    <Link to={'/album-selection?id=' + dd.booking_id} key={obj.booking_pk} className='hollow-btn tab-btn grid-item'><MdOutlinePhotoSizeSelectLarge />Album Selection</Link>
                                                                ))} */}
                                                                {/* {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj.is_share_url_exist === true)).map(obj => (
                                                                    <button key={obj.booking_pk} className='hollow-btn tab-btn grid-item' onClick={() => { getShareDetail(obj.booking_pk, dd.booking_id) }}><HiOutlineShare />Shared Url List</button>
                                                                ))} */}


                                                                {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj.payment_detail.length > 0)).map(obj => (
                                                                    <button className='hollow-btn tab-btn grid-item' key={obj.booking_pk} onClick={() => { showPaymentHistory(dd.booking_id) }}><MdOutlinePayment />Payment History</button>
                                                                ))}

                                                                {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj.event_detail.length > 0 && !obj.estimations)).map(obj => (
                                                                    <button className='hollow-btn tab-btn grid-item' key={obj.booking_pk} onClick={() => { showEventDetails(dd.booking_id) }}><MdOutlineLibraryBooks />Event Detail</button>
                                                                ))}


                                                            </div> :
                                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                                    {globalLoader()}
                                                                </div>
                                                            }
                                                        </div>
                                                        {bookingDetail.length > 1 && <div style={{ display: "flex", flexDirection: "column" }}>
                                                            {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj.estimations && obj.estimations.length > 0)).map(obj => (
                                                                <>
                                                                    <div style={{ display: obj.paid_amount === null && "none", fontSize: dashWidth < 600 ? "normal" : "large", fontWeight: "bold" }}>
                                                                        <div style={{ padding: "0.7rem 0" }}>Total amount paid : {obj.paid_amount}</div>
                                                                        <Divider orientation='horizontal' />
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>}

                                                        {bookingDetail.length > 1 && <div style={{ display: "flex", justifyContent: "center" }}>
                                                            {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj.estimations && obj.estimations.length > 0)).map(obj => (
                                                                <>
                                                                    <div className='multipleEst' style={{ width: obj.estimations.length === 1 && dashWidth >= 700 && "90%", marginTop: "10px" }}>
                                                                        {bookingDetail?.filter(obj => obj.booking_id?.includes(dd.booking_id) && (obj.estimations.length > 0)).map(obj => (
                                                                            <>
                                                                                {obj.estimations.map((e, index) => {
                                                                                    return <>
                                                                                        {(obj.estimations.length > 1 || dashWidth < 700) && <Link to={e.url} key={e.url}>
                                                                                            <Card maxW='12rem' style={{ border: index === 0 && obj.estimations.length > 1 && "5px solid #C6F6D5", fontSize: "0.9rem", marginRight: index < obj.estimations.length - 1 && "1.3rem" }}>
                                                                                                <div style={{ margin: "15px" }}>
                                                                                                    <div style={{ paddingBottom: "7px", display: "flex", justifyContent: "space-between", alignItems: "center" }}><span>{quotation_string(e.version)[0]} <span style={{ fontWeight: "bold" }}>{quotation_string(e.version)[1]}</span></span><span style={{ display: !e.is_final && "none", border: "1px solid #aa8440", padding: "0 2px", borderRadius: "2rem", color: "white", backgroundColor: "#aa8440", padding: "0 10px", fontSize: "smaller" }}>Final</span></div>
                                                                                                    <div style={{ paddingBottom: "7px", textAlign: "left" }}>Created : <span style={{ fontWeight: "bold" }}>{e.created_on}</span></div>
                                                                                                    <div style={{ textAlign: "left" }}>Cost : <span style={{ fontWeight: "bold" }}>{e.cost}</span> </div>
                                                                                                </div>
                                                                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                                                                    <div style={{ border: "2px solid black", width: "fit-content", margin: "0 15px 15px 15px", padding: "5px 20px", borderRadius: "10px" }}>View</div>
                                                                                                </div>
                                                                                            </Card>
                                                                                        </Link>}
                                                                                        {(obj.estimations.length === 1 && dashWidth >= 700) && <Link style={{ width: obj.estimations.length === 1 && dashWidth >= 700 && "100%" }} to={e.url} key={e.url}>
                                                                                            <Card minW='100%' style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: "0 10%", width: "fit-content" }}>
                                                                                                <div style={{ margin: "15px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "1.5rem" }}><span style={{ paddingRight: "1rem" }}>{quotation_string(e.version)[0]} <span style={{ fontWeight: "bold" }}>{quotation_string(e.version)[1]}</span></span><span style={{ display: !e.is_final && "none", border: "1px solid #aa8440", padding: "0 2px", borderRadius: "2rem", color: "white", backgroundColor: "#aa8440", padding: "0 10px", fontSize: "smaller" }}>Final</span></div>
                                                                                                    <div style={{ paddingRight: "1.5rem" }}>Created : <span style={{ fontWeight: "bold" }}>{e.created_on}</span></div>
                                                                                                    <div style={{ paddingRight: "1.5rem" }}>Cost : <span style={{ fontWeight: "bold" }}>{e.cost}</span> </div>
                                                                                                </div>
                                                                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                                                                    <div style={{ border: "2px solid black", width: "fit-content", margin: "15px", padding: "5px 20px", borderRadius: "10px" }}>View</div>
                                                                                                </div>
                                                                                            </Card>
                                                                                        </Link>}
                                                                                    </>
                                                                                })}
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>}
                                                        {(openShare === true) ? sharePopup : ""}

                                                        {onOpenAlert ? alert : ""}

                                                        {(openEventDetail === true) ? eventDetailPopup : ""}

                                                        {(openpaymentHistory === true) ? paymentHistoryPopup : ""}

                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </React.Fragment>
                                        </>
                                    })}
                                </>
                            }
                        </Accordion>

                    }
                </div>

                :
                <div className='fullpage-overlay'>
                    {globalLoader()}
                </div>
            }

        </>

    )

}

export default Dashboard